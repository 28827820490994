import { InfoViewState, InfoViewTypes } from './InfoViewContext';

type InfoViewAction = 'FETCH_STARTS' | 'FETCH_SUCCESS' | 'SET_MESSAGE' | 'SET_ERROR';

export function contextReducer(state = InfoViewState, action: { type: InfoViewAction; payload?: any }): InfoViewTypes {
  switch (action.type) {
    case 'FETCH_STARTS': {
      return {
        ...state,
        loading: true,
        message: '',
        error: '',
      };
    }
    case 'FETCH_SUCCESS': {
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: '',
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: '',
      };
    }
    case 'SET_ERROR': {
      console.log('setting error ', action.payload);
      return {
        ...state,
        loading: false,
        message: '',
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
