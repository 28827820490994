import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLogger } from '@vf/services/LoggingService';
import { VFAppLayout, VFHelpMenuProps } from '@voicefoundry-cloud/vf-storybook';
import { Auth } from 'aws-amplify';
import AppRoutes from 'modules/AppRoutes';
import RouteDefinition from 'modules/RouteDefinition';
import { useAppContext } from './ContextProvider';
import { Alert, Button, Snackbar } from '@mui/material';
import { useIdleTimer } from 'react-idle-timer';

const logger = getLogger('AuthRoutes');

interface AuthRoutesProps {
  children: ReactNode;
}

const ActivityTimer = (props: any) => {
  const { config } = useAppContext();
  const DEFUALT_TIMEOUT = 600000; //10 Minutes
  const DEFAULT_PROMPT_BEFORE_IDLE = 120000; //2 Minutes

  const disabled = !config.inactivityTimer.enabled ?? true;
  const timeout = config.inactivityTimer.timeout ?? DEFUALT_TIMEOUT;
  const promptBeforeIdle = config.inactivityTimer.promptBeforeIdle ?? DEFAULT_PROMPT_BEFORE_IDLE;
  const [remaining, setRemaining] = useState<number>(timeout);
  const [showWarning, setShowWarning] = useState(false);
  const navigate = useNavigate();

  const milliSecondsToMinutesSeconds = (milliseconds: number) => {
    const minutes = Math.floor(milliseconds / (1000 * 60))
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor((milliseconds / 1000) % 60)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const onIdle = async () => {
    await Auth.signOut();
    navigate('/');
  };

  const onActive = () => {
    setShowWarning(false);
  };

  const onAction = () => {
    activate();
  };

  const onPrompt = () => {
    setShowWarning(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime()));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  /**
   * Extends the user session by refreshing all tokens.
   */
  const extendSession = async () => {
    const bool = await Auth.currentAuthenticatedUser({
      bypassCache: true, // Forces a refresh of all tokens
    });
    if (bool) {
      setShowWarning(false);
    }
  };
  /**
   * Handles the close event of the component.
   * @param {Event} event - The event object.
   * @param {string} reason - The reason for the close event.
   * @returns {void}
   */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return; // Prevents closing when clicking outside
    }
    setShowWarning(false);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    onAction,
    promptBeforeIdle,
    timeout,
    throttle: 500,
    crossTab: false,
    disabled,
  });
  return (
    <>
      <Snackbar open={showWarning} onClose={handleClose}>
        <Alert
          aria-label="Session idle Warning"
          onClose={() => {
            setShowWarning(false);
          }}
          action={
            <>
              <Button
                id="dismiss-idle-session"
                tabIndex={0}
                sx={{
                  mr: '10px',
                }}
                aria-label="dismiss extend session alert"
                color="secondary"
                variant="outlined"
                onClick={() => setShowWarning(false)}>
                Dismiss
              </Button>
              <Button
                id="extend-idle-session"
                color="success"
                aria-label="extend idle session"
                variant="contained"
                onClick={extendSession}>
                Extend Session
              </Button>
            </>
          }
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}>
          {`You will be logged out in ${milliSecondsToMinutesSeconds(remaining)}.`} <b>{'Would you like to extend?'}</b>
        </Alert>
      </Snackbar>
      {props.children}
    </>
  );
};

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  logger.debug(`Initializing Provider`);
  const { user, config } = useAppContext();
  const navigate = useNavigate();
  const routeDef = RouteDefinition();

  const helpMenuProps: VFHelpMenuProps = {
    helpTopics: [
      { topicTitle: 'Release Notes', icon: 'info', path: 'help/release-notes' },
      { topicTitle: 'Lambda Function Reference', icon: 'code', path: 'help/lambda-reference' },
    ],
    buttonColor: '#fff',
    buttonSize: 'large',
  };

  const signOutButton = async function signOut(): Promise<void> {
    try {
      await Auth.signOut();
      navigate('/');
    } catch (error) {
      console.warn('error signing out: ', error);
    }
  };

  return (
    <>
      {children}
      <ActivityTimer>
        <VFAppLayout
          AppRoutes={AppRoutes}
          helpMenuProps={helpMenuProps}
          routesDefinition={routeDef}
          useAuth={true}
          onLogout={signOutButton}
          user={user!}
          useDefaultHeaderChips={true}
          config={{ environment: config.environment }}
          footerVersion={config.version}
          customLogo={
            config.branding?.logoUrl ?
              <div>
                <a href="#/">
                  <img style={{ maxHeight: '100%' }} src={config.branding.logoUrl} aria-label="Company Logo" />
                </a>
              </div>
            : undefined
          }
        />
      </ActivityTimer>
    </>
  );
};

export default AuthRoutes;
