import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { OpenCheckTypes } from '@vf-omp/shared';
import config from './config';
import { IMessage } from 'types/models/IMessage';
import { hoursReducer } from './hoursReducer';
import { ENTITY_TYPES, getEntityAttrValueHelper } from 'contexts/SharedContextModels';
export const hoursState = {
  ...config,
};
const useHours = () => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(hoursReducer, hoursState);

  useEffect(() => {
    listHours();
    getQueues();
    getNumbers();
    getTimezones();
  }, [api]);

  const listHours = async () => {
    dispatch({ type: 'LOADING_HOURS', payload: true });
    try {
      const hoursList = await api.hour.getAllHours();
      dispatch({ type: 'LIST', payload: { hoursList } });
    } catch (err) {
      dispatch({ type: 'LOADING_HOURS', payload: false });
      dispatch({
        type: 'SET_ERROR',
        payload: { error: 'Server error while loading hours data' },
      });
    }
  };

  useEffect(() => {
    if (state.hoursList.length === 0) return;
    for (const hours of state.hoursList) {
      for (const association of hours.queues) {
        dispatch({
          type: 'ADD_ASSOCIATION_KEY',
          payload: association,
        });
      }
    }
  }, [state.hoursList]);

  const addAssociationToDict = (association: { key: string; type: OpenCheckTypes }): void => {
    console.log('Adding association ', association);
    dispatch({
      type: 'ADD_ASSOCIATION_KEY',
      payload: association,
    });
  };
  const removeAssociationFromDict = (association: { key: string; type: OpenCheckTypes }): void => {
    dispatch({
      type: 'REMOVE_ASSOCIATION_KEY',
      payload: association,
    });
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: {
          queues,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getNumbers = async () => {
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: {
          numbers,
        },
      });
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
    }
  };
  const getTimezones = async () => {
    try {
      const timezones = await api.helpers.getAllTimezones();
      dispatch({
        type: 'GET_TIMEZONES',
        payload: {
          timezones,
        },
      });
    } catch (err) {
      console.warn('Unable to load timezones', err);
    }
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: { msg } });
  };

  const getEntityAttrValue = (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr: string = null) =>
    getEntityAttrValueHelper(state, type, keyType, keyValue, returnAttr);

  return {
    ...state,
    setMsg,
    getEntityAttrValue,
    listHours,
    addAssociationToDict,
    removeAssociationFromDict,
  };
};

type UseHoursType = ReturnType<typeof useHours>;

export const HoursContext = createContext<UseHoursType | null>(null);

export const useHoursContext = () => useContext(HoursContext)!;

export const HoursManagementContextProvider = ({ children }: { children: ReactNode }) => (
  <HoursContext.Provider value={useHours()}>{children}</HoursContext.Provider>
);
