import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { useAppContext } from '@vf/utility/ContextProvider';
import { AttributeVm, ConfigSetVm, Prompt, PromptVm } from '@vf-omp/shared';
import {
  PromptSummary,
  QueueSummary,
  UserSummary,
  PhoneNumberSummary,
  ContactFlowSummary,
} from '@aws-sdk/client-connect';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { CRUD_Helpers, initialCrudHelpers } from './initialStates';

const cfTemplate: ConfigSetVm = {
  name: '',
  prompts: [],
  attributes: [],
};

interface WorkingLanguagePrompt extends Prompt {
  id: string;
  promptName: string;
  isNewPrompt: boolean;
}

const useConfigSets = () => {
  const api = useContext(ApiContext);

  const [configSets, setConfigSets] = useState<ConfigSetVm[]>([]);
  const [activeConfigSet, setActiveConfigSet] = useState<ConfigSetVm>({
    ...cfTemplate,
  });
  const [workingLanguagePrompt, setWorkingLanguagePrompt] = useState<WorkingLanguagePrompt | undefined>();
  const [crudHelpers, setCrudHelpers] = useState<CRUD_Helpers>(initialCrudHelpers);
  // Resources for Config Sets
  const [connectPrompts, setConnectPrompts] = useState<PromptSummary[]>([]);
  const [connectQueues, setConnectQueues] = useState<QueueSummary[]>([]);
  const [connectUsers, setConnectUsers] = useState<UserSummary[]>([]);
  const [connectPhoneNumbers, setConnectPhoneNumbers] = useState<PhoneNumberSummary[]>([]);
  const [connectContactFlows, setConnectContactFlows] = useState<ContactFlowSummary[]>([]);
  const { pollyLanguageList, config, getDisplayLang } = useAppContext();
  const supportedOMPLanguages = config.supportedLanguages;
  const supportedPollyLanguages = pollyLanguageList;

  const predictiveAttributeNameOptions = useMemo(() => {
    return configSets
      .reduce((prev, curr) => {
        const newAttrNames = curr.attributes
          .filter(attr => prev.find(attrName => attr.name === attrName) === undefined)
          .map(attr => attr.name);
        return [...prev, ...newAttrNames];
      }, [] as string[])
      .sort((a, b) => (a > b ? 1 : -1));
  }, [configSets]);

  useEffect(() => {
    getConfigSets();
    getConnectPrompts();
    getConnectPhoneNumbers();
    getConnectQueues();
    getConnectUsers();
    getConnectContactFlows();
  }, []);

  const resetCrudHelpers = () => {
    setCrudHelpers({ ...initialCrudHelpers });
  };

  const updateConfigSets = (newConfigSet: ConfigSetVm) => {
    const index = configSets.findIndex(({ name }) => name === newConfigSet.name);

    configSets.splice(index, 1, newConfigSet);

    setConfigSets(configSets);
  };

  const getConnectQueues = async () => {
    try {
      const queueSummaries = await api.connect.listQueues();
      setConnectQueues(queueSummaries);
    } catch (error) {
      console.log('Load Queues Error:', error);
    }
  };
  const getConnectUsers = async () => {
    try {
      const userSummaries = await api.connect.listUsers();
      setConnectUsers(userSummaries);
    } catch (error) {
      console.log('Load Users Error: ', error);
    }
  };
  const getConnectPhoneNumbers = async () => {
    try {
      const phoneNumberSummaries = await api.connect.listNumbers();
      setConnectPhoneNumbers(phoneNumberSummaries);
    } catch (error) {
      console.log('Load Phone Numbers Error: ', error);
    }
  };

  const getConnectContactFlows = async () => {
    try {
      const contactFlows = await api.connect.listContactFlows();
      setConnectContactFlows(contactFlows);
    } catch (error) {
      console.log('Load Contact Flows Error: ', error);
    }
  };

  const getConnectPrompts = async () => {
    try {
      const prompts = await api.connect.listPrompts();
      setConnectPrompts(prompts);
      setCrudHelpers(prev => ({
        ...prev,
        openBackdrop: false,
        severity: 'success',
        openAlert: true,
        alertMessage: 'The Connect prompts loaded successfully.',
      }));
    } catch (error) {
      setCrudHelpers(prev => ({
        ...prev,
        openBackdrop: false,
        severity: 'error',
        openAlert: true,
        alertMessage: 'Failed to get Connect prompts.',
      }));
    }
  };

  const getConfigSets = async () => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Retrieving Configuration Sets...' }));
    return api.configSet
      .getConfigSets()
      .then(data => {
        setConfigSets(data);
        setCrudHelpers(prev => ({ ...prev, openBackdrop: false }));
        return true;
      })
      .catch(error => {
        console.log(error);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to load configuration set',
        }));
        return false;
      });
  };

  const createContactFlow = async (flow, prompt, attribute) => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Creating Configuration Set...' }));
    try {
      if (prompt.name.trim() !== '' && attribute.name.trim() !== '') {
        Promise.all([api.configSet.postPrompt(prompt, flow), api.configSet.createAttribute(attribute, flow)]).then(
          async () => {
            const cfs = await api.configSet.getConfigSets();
            const selectedFlow = cfs.find(x => x.name === flow);
            setConfigSets(cfs);
            setActiveConfigSet(selectedFlow);
            setCrudHelpers(prev => ({
              ...prev,
              openBackdrop: false,
              severity: 'success',
              openAlert: true,
              alertMessage: 'The configuration set created successfully with new prompt & attribute',
            }));
          }
        );
      } else if (prompt.name.trim() !== '') {
        api.configSet.postPrompt(prompt, flow).then(async () => {
          const cfs = await api.configSet.getConfigSets();
          const selectedFlow = cfs.find(x => x.name === flow);
          setConfigSets(cfs);
          setActiveConfigSet(selectedFlow);
          setCrudHelpers(prev => ({
            ...prev,
            openBackdrop: false,
            severity: 'success',
            openAlert: true,
            alertMessage: 'The configuration set created successfully with new prompt',
          }));
        });
      } else if (attribute.name.trim() !== '') {
        api.configSet.createAttribute(attribute, flow).then(async () => {
          const cfs = await api.configSet.getConfigSets();
          const selectedFlow = cfs.find(x => x.name === flow);
          setConfigSets(cfs);
          setActiveConfigSet(selectedFlow);
          setCrudHelpers(prev => ({
            ...prev,
            openBackdrop: false,
            severity: 'success',
            openAlert: true,
            alertMessage: 'The configuration set created successfully with new attribute',
          }));
        });
      }
    } catch (err) {
      console.log(err);
      setCrudHelpers(prev => ({
        ...prev,
        openBackdrop: false,
        severity: 'error',
        openAlert: true,
        alertMessage: 'Unable to create configuration set',
      }));
    }
  };

  const isSelected = name => {
    return activeConfigSet.name === name;
  };

  const getPromptsAttributes = (flow: string) => {
    setActiveConfigSet(configSets.find(x => x.name === flow));
  };

  const createPrompt = prompt => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Creating Prompt...' }));
    api.configSet
      .postPrompt(prompt, activeConfigSet.name)
      .then(data => {
        activeConfigSet.prompts = [...activeConfigSet.prompts, data];
        setActiveConfigSet(activeConfigSet);
        updateConfigSets(activeConfigSet);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The configuration set prompt created successfully.',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to create configuration set prompt',
        }));
      });
  };

  const createAttribute = attribute => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true }));
    api.configSet
      .createAttribute(attribute, activeConfigSet.name)
      .then(data => {
        activeConfigSet.attributes = [...activeConfigSet.attributes, data];
        setActiveConfigSet(activeConfigSet);
        updateConfigSets(activeConfigSet);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The configuration set attribute created successfully.',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to create configuration set attribute',
        }));
      });
  };

  const deletePrompt = (id: string) => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Deleting Prompt...' }));
    api.configSet
      .deletePrompt(activeConfigSet.name, id)
      .then(data => {
        activeConfigSet.prompts = activeConfigSet.prompts.filter(obj => obj.id !== id);
        setActiveConfigSet(activeConfigSet);
        updateConfigSets(activeConfigSet);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The prompt deleted succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to delete prompt',
        }));
      });
  };

  const deleteAttribute = (id: string) => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Deleting Attribute...' }));
    api.configSet
      .deleteAttribute(activeConfigSet.name, id)
      .then(data => {
        const updatedFlow: ConfigSetVm = {
          ...activeConfigSet,
          attributes: (activeConfigSet.attributes = activeConfigSet.attributes.filter(obj => obj.id !== id)),
        };
        setActiveConfigSet(updatedFlow);
        updateConfigSets(updatedFlow);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The attribute deleted succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to delete attribute',
        }));
      });
  };

  const updateAttribute = (attribute: AttributeVm) => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Updating Attribute...' }));
    const updateAttr = activeConfigSet.attributes.find(attr => attr.id === attribute.id);
    updateAttr.data = attribute.data;

    api.configSet
      .updateAttribute(updateAttr, activeConfigSet.name)
      .then(data => {
        activeConfigSet.attributes = activeConfigSet.attributes.map(attribute => {
          if (attribute.id === data.id) {
            attribute.data = data.data;
          }

          return attribute;
        });
        setActiveConfigSet(activeConfigSet);
        updateConfigSets(activeConfigSet);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The attribute updated succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to update attribute',
        }));
      });
  };

  const updatePromptStatus = (id: string, status: boolean) => {
    const promptVm = activeConfigSet.prompts.find(e => e.id === id);
    promptVm.disabled = status;
    putPrompt(promptVm);
  };

  const updatePrompt = (id: string, prompt: Prompt, backdropMessage?: string) => {
    const promptVm = activeConfigSet.prompts.find(e => e.id === id);
    const updated = { ...promptVm, data: [...promptVm.data.filter(obj => obj.lang !== prompt.lang), prompt] };
    putPrompt(updated, backdropMessage);
  };

  const updatePromptByLng = (id: string, lang: string) => {
    const promptVm = activeConfigSet.prompts.find(e => e.id === id);
    const newPrompt: PromptVm = { ...promptVm, data: promptVm.data.filter(obj => obj.lang !== lang) };
    putPrompt(newPrompt);
  };

  const deletePromptLanguage = (promptId: string, langCode: string) => {
    const promptVm = activeConfigSet.prompts.find(prompt => prompt.id === promptId);
    const langRemoved = { ...promptVm, data: [...promptVm.data.filter(lp => lp.lang !== langCode)] };
    putPrompt(langRemoved, `Deleting ${getDisplayLang(langCode)} (${langCode}) from ${promptVm.name}`);
  };

  const putPrompt = (prompt: PromptVm, backdropMessage?: string) => {
    setCrudHelpers(prev => ({
      ...prev,
      openBackdrop: true,
      backdropMessage: backdropMessage || 'Updating Prompt Set...',
    }));
    api.configSet
      .putPrompt(prompt, activeConfigSet.name)
      .then(data => {
        const updatedConfigSet: ConfigSetVm = {
          ...activeConfigSet,
          prompts: [
            ...activeConfigSet.prompts.map(prompt => {
              if (prompt.id === data.id) {
                prompt.data = data.data.sort((a, b) => (a.lang > b.lang ? 1 : -1));
                prompt.name = data.name;
                prompt.disabled = data.disabled;
              }

              return prompt;
            }),
          ],
        };
        setActiveConfigSet(updatedConfigSet);
        updateConfigSets(updatedConfigSet);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The prompt updated succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to update prompt',
        }));
      });
  };

  const deleteContactFlow = (id: string) => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Deleting Configuration Set...' }));
    api.configSet
      .deleteConfigSet(id)
      .then(data => {
        setConfigSets([...configSets.filter(obj => obj.name !== id)]);
        if (activeConfigSet.name === id) {
          setActiveConfigSet({ ...cfTemplate });
        }
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The config set deleted succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to delete config set',
        }));
      });
  };

  return {
    configSets,
    activeConfigSet,
    crudHelpers,
    cfTemplate,
    workingLanguagePrompt,
    supportedOMPLanguages,
    supportedPollyLanguages,
    connectPhoneNumbers,
    connectContactFlows,
    connectPrompts,
    connectQueues,
    connectUsers,
    predictiveAttributeNameOptions,
    getDisplayLang,
    setWorkingLanguagePrompt,
    setCrudHelpers,
    resetCrudHelpers,
    createAttribute,
    createContactFlow,
    createPrompt,
    getConfigSets,
    setActiveConfigSet,
    getPromptsAttributes,
    updateAttribute,
    updateConfigSets,
    updatePrompt,
    updatePromptByLng,
    updatePromptStatus,
    deleteContactFlow,
    deleteAttribute,
    deletePrompt,
    deletePromptLanguage,
    isSelected,
  };
};

export type UseConfigSetsType = ReturnType<typeof useConfigSets>;
// Context
export const ConfigSetContext = createContext<UseConfigSetsType | null>(null);
export const useConfigSetContext = () => useContext(ConfigSetContext)!;

export const ConfigSetContextProvider = ({ children }: { children: ReactNode }) => (
  <ConfigSetContext.Provider value={useConfigSets()}>{children}</ConfigSetContext.Provider>
);
