import TenantContextPropsType from './ContextPropTypes';

type TenantAction =
  | 'AVAILABLE_TENANTS_LOADED'
  | 'SET_AVAILABLE_TENANTS'
  | 'CURRENT_TENANT_LOADED'
  | 'SET_CURRENT_TENANT'
  | 'FETCHING_DATA'
  | 'FETCHING_SUCCESS'
  | 'FETCHING_ERROR'
  | 'SET_MESSAGE';

export function reducer(state: any, action: { type: TenantAction; payload?: any }): TenantContextPropsType {
  switch (action.type) {
    case 'FETCHING_DATA':
      return {
        ...state,
        loadingData: true,
      };
    case 'FETCHING_SUCCESS':
      return {
        ...state,
        loadingData: false,
      };
    case 'FETCHING_ERROR':
      return {
        ...state,
        loadingData: false,
        msg: action.payload.msg,
      };
    case 'SET_AVAILABLE_TENANTS': {
      return {
        ...state,
        fetchingData: false,
        tenantsLoaded: true,
        availableTenants: action.payload,
      };
    }
    case 'SET_CURRENT_TENANT': {
      return {
        ...state,
        fetchingData: false,
        currentTenantLoaded: true,
        currentTenant: action.payload,
      };
    }
    case 'SET_MESSAGE':
      return {
        ...state,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
}
