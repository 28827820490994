import * as React from 'react';
import { VFRouteDefinition } from '@voicefoundry-cloud/vf-storybook/dist/models/navigation';
import { parseAuthorizedRoutes, iNavItemProps } from './AppRoutes';
import { useState } from 'react';
import { useAppContext } from '@vf/utility/ContextProvider';

/**
 * @description Transforms the routes that we have defined in the OMP,
 *  to the Route Definition format that vf-Storybook is expecting
 */
const standardizeRoute = (input: iNavItemProps): VFRouteDefinition => {
  let children;
  if (input.children) {
    children = input.children.map(o => standardizeRoute(o));
  }
  return {
    title: input.title,
    path: input.url,
    icon: input.icon,
    auth: input.auth,
    children,
  };
};

const RouteDefinition = (): VFRouteDefinition[] => {
  const appCtx = useAppContext();
  const [routeDefs, setRouteDefs] = useState<VFRouteDefinition[]>();

  React.useEffect(() => {
    (async () => {
      /**
       * Filters the routes based on if they have a icon defined or not.
       * If they have an icon, we can assume that they belong in the vertical side-menu
       */
      const filteredRoutes: VFRouteDefinition[] = (
        await parseAuthorizedRoutes(appCtx.config.plugins, appCtx.user)
      ).reduce((a, c) => {
        if (c.icon) {
          a.push(standardizeRoute(c));
        }
        return a;
      }, []);
      setRouteDefs([...filteredRoutes]);
    })();
  }, [appCtx]);

  return routeDefs;
};

export default RouteDefinition;
