import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { iNavItemProps } from 'modules/AppRoutes';

const MarkdownContentViewer = lazy(() => import('./HelpMDContentViewer'));

export const HelpRoutes: iNavItemProps[] = [
  {
    id: 'help-release-notes',
    messageId: 'Help - Release Notes',
    title: 'Help - Release Notes',
    url: '/help/:topic',
    route: <Route key="help-pages" path="/help/:topic" element={<MarkdownContentViewer />} />,
    auth: [],
  },
];
