/* eslint-disable quotes */
import dayjs from 'dayjs';
import { AgentEvent, Configuration, Contact, Queue, RoutingProfile } from '@voicefoundry-cloud/vf-connect-data-models';

export type AgentActivity =
  | 'Available'
  | 'Incoming'
  | 'On contact'
  | 'Rejected'
  | 'Missed'
  | 'Error'
  | 'After contact work'
  | string;

export interface IAgentRealTimeMetric {
  agentArn: string;
  eventTimestamp: string;
  UserName: string;

  CurrentActivity: AgentActivity;
  CurrentStartTime: string; //timestamp
  Duration: number;

  TotalAfterCallWork: number; // total seconds
  TotalNonResponseTime: number; // total seconds
  TotalHandleTime: number;
  TotalIncomingConnectingTime: number;
  TotalOutboundConnectingTime: number;
  TotalHoldTime: number;

  AgentNonResponse: number;
  AfterCallWorkContacts: number;
  Rejected: number;
  InboundContacts: number;
  OutboundContacts: number;
  TotalContacts: number;
  CallbackContacts: number;
  ApiContacts: number;
  DisconnectedContacts: number;
  AbandonedContacts: number;
  ContactHolds: number;
  TotalTalkTime: number;

  AverageAfterCallWork: number;
  AverageHandleTime: number;

  CurrentQueue: {
    ARN: string;
    Name: string;
  };
  Contacts?: Contact[];
}

export class AgentRealTimeMetric implements IAgentRealTimeMetric {
  agentArn: string;
  eventTimestamp: string;
  UserName: string;

  CurrentActivity: AgentActivity = null;
  CurrentStartTime: string = null;
  Duration: number = 0;

  AgentNonResponse: number = 0;
  AfterCallWorkContacts: number = 0;
  Rejected: number = 0;
  InboundContacts: number = 0;
  OutboundContacts: number = 0;
  ContactHolds: number = 0;
  AbandonedContacts: number = 0;
  TotalContacts: number = 0;
  CallbackContacts: number = 0;
  ApiContacts: number = 0;
  DisconnectedContacts: number = 0;

  TotalAfterCallWork: number = 0;
  TotalNonResponseTime: number = 0;
  TotalHandleTime: number = 0;
  TotalIncomingConnectingTime: number = 0;
  TotalOutboundConnectingTime: number = 0;
  TotalHoldTime: number = 0;
  TotalTalkTime: number = 0;

  AverageAfterCallWork: number = 0;
  AverageHandleTime: number = 0;

  CurrentQueue: {
    ARN: string;
    Name: string;
  } = null;
  Contacts?: Contact[] = [];

  constructor(record: AgentEvent) {
    this.agentArn = record.AgentARN;
    this.UserName = record.CurrentAgentSnapshot.Configuration.Username;
  }
}

export class AgentRealTimeMetricOutgoing implements IAgentRealTimeMetric {
  agentArn: string;
  eventTimestamp: string;
  UserName: string;

  CurrentActivity: AgentActivity = null;
  CurrentStartTime: string = null;
  Duration: number = 0;

  AgentNonResponse: number = 0;
  AfterCallWorkContacts: number = 0;
  Rejected: number = 0;
  InboundContacts: number = 0;
  OutboundContacts: number = 0;
  ContactHolds: number = 0;
  AbandonedContacts: number = 0;
  TotalContacts: number = 0;
  CallbackContacts: number = 0;
  ApiContacts: number = 0;
  DisconnectedContacts: number = 0;

  TotalAfterCallWork: number = 0;
  TotalNonResponseTime: number = 0;
  TotalHandleTime: number = 0;
  TotalIncomingConnectingTime: number = 0;
  TotalOutboundConnectingTime: number = 0;
  TotalHoldTime: number = 0;
  TotalTalkTime: number = 0;

  AverageAfterCallWork: number = 0;
  AverageHandleTime: number = 0;

  CurrentQueue: {
    ARN: string;
    Name: string;
  } = null;
  Contacts?: Contact[] = [];
  // eslint-disable-next-line no-unused-vars
  constructor(input?: { [String in keyof IAgentRealTimeMetric]?: any }) {
    if (input) Object.assign(this, input);
  }
}

export class AgentRealTimeMetricItem {
  key: string;
  id: string;
  metrics: AgentRealTimeMetric;
  constructor(metrics: AgentRealTimeMetric) {
    this.key = metrics.agentArn;
    this.id = dayjs(metrics.eventTimestamp).format('YYYY-MM-DD');
    this.metrics = metrics;
  }
}

export class AgentConfigurationItem {
  key: string;
  id: string;
  configuration: Configuration;
  CurrentActivity: AgentActivity = null;
  CurrentStartTime: string = null;
  RoutingProfileId?: string = null;
  RoutingProfileName?: string = null;
  RoutingProfileQueues?: Queue[] = [];
  RoutingProfileDefaultOutboundQueue: Queue;
  RoutingProfile: RoutingProfile = null;
  AgentHierarchyIds?: [string, string, string, string, string] = [null, null, null, null, null];
  AgentHierarchyNames?: [string, string, string, string, string] = [null, null, null, null, null];
  AgentHierarchyDisplay?: string;

  constructor(record: IAgentRealTimeMetric, config: Configuration) {
    this.key = record.agentArn;
    this.id = 'agent-configuration';
    this.configuration = config;
    this.CurrentActivity = record.CurrentActivity;
    this.CurrentStartTime = record.CurrentStartTime;
    const level1 = config.AgentHierarchyGroups.Level1;
    const level2 = config.AgentHierarchyGroups.Level2;
    const level3 = config.AgentHierarchyGroups.Level3;
    const level4 = config.AgentHierarchyGroups.Level4;
    const level5 = config.AgentHierarchyGroups.Level5;
    this.AgentHierarchyIds = [
      level1 ? level1.ARN : null,
      level2 ? level2.ARN : null,
      level3 ? level3.ARN : null,
      level4 ? level4.ARN : null,
      level5 ? level5.ARN : null,
    ];
    this.AgentHierarchyNames = [
      level1 ? level1.Name : null,
      level2 ? level2.Name : null,
      level3 ? level3.Name : null,
      level4 ? level4.Name : null,
      level5 ? level5.Name : null,
    ];
    this.AgentHierarchyDisplay = this.AgentHierarchyNames.filter(n => n).join(' / ');
    this.RoutingProfile = config.RoutingProfile;
    this.RoutingProfileId = config.RoutingProfile.ARN;
    this.RoutingProfileName = config.RoutingProfile.Name;
    this.RoutingProfileQueues = config.RoutingProfile.InboundQueues;
    this.RoutingProfileDefaultOutboundQueue = config.RoutingProfile.DefaultOutboundQueue;
  }
}

export type AgentConfigurationList = AgentConfigurationItem[];
