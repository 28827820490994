import { HolidaysState } from './HolidaysManagementContextPropTypes';

const config: HolidaysState = {
  holidaysList: [],
  prompts: [],
  holidaysLoaded: false,
  promptsLoaded: false,
  deletingHolidays: false,
  loadingHolidays: false,
  loadingPrompts: false,
  deleting: false,
  queues: [],
  queuesLoaded: false,
  numbers: [],
  numbersLoaded: false,
  timezones: [],
  timezonesLoaded: false,
  msg: undefined,
  error: '',
  connectPrompts: [],
};

export default config;
