import { useState, useContext, useReducer, useEffect, createContext, ReactNode } from 'react';
import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { ContactFlowSummary, PromptSummary } from '@aws-sdk/client-connect';
import { Menu, OMPListBotsData, Prompt } from '@vf-omp/shared';
import { menuReducer } from './menuReducer';
import { VF_DEFAULT_QUEUE_FLOW_NAME_REGEX } from '../../defaultUtils/regexUtils';
import { getInitalMenuState, getInitialWorkingMenu } from './getInitialMenuState';
import { LexMenuOptions } from './MenuManagementContextPropTypes';
import useMenuValidators from './useMenuValidators';

export enum ENTITY_TYPES {
  FLOWS = 'flows',
  CUSTOMER_QUEUE_FLOWS = 'customerQueueFlows',
  QUEUES = 'queues',
  PROMPTS = 'prompts',
  AGENTS = 'agentQueues',
}

export type CreateMenuStepsType =
  | 'ASSOCIATE_BOT'
  | 'SELECT_BOT'
  | 'BASIC_CONFIG'
  | 'CONFIGURE_PROMPTS'
  | 'CONFIGURE_OPTIONS';

export type UpsertActionsType = 'CREATE' | 'UPDATE' | 'DELETE';
export interface UpsertHelpers {
  upsertAction: UpsertActionsType;
  currentCreateMenuStep: CreateMenuStepsType;
  createMenuStepOrder: CreateMenuStepsType[];
  openBackdrop: boolean;
  loadingMessage: string;
  errorMessage: string;
  warningMessage: string;
  successMessage: string;
  infoMessage: string;
  menuSearchTerm: string;
}

const initialUpserHelpersState: UpsertHelpers = {
  upsertAction: undefined,
  currentCreateMenuStep: undefined,
  createMenuStepOrder: ['ASSOCIATE_BOT', 'SELECT_BOT', 'BASIC_CONFIG', 'CONFIGURE_PROMPTS', 'CONFIGURE_OPTIONS'],
  openBackdrop: false,
  loadingMessage: '',
  errorMessage: '',
  successMessage: '',
  warningMessage: '',
  infoMessage: '',
  menuSearchTerm: '',
};

const useMenuManagement = () => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(menuReducer, getInitalMenuState());
  const [connectPrompts, setConnectPrompts] = useState<PromptSummary[]>([]);
  const [upsertHelpers, setUpsertHelpers] = useState<UpsertHelpers>(initialUpserHelpersState);
  const [pollyWorkingLanguagePrompt, setPollyWorkingLanguagePrompt] = useState<Prompt>({ lang: '', value: '' });
  const menuValidators = useMenuValidators({
    menuManagementState: state,
    upsertHelpers,
  });

  useEffect(() => {
    if (state.menusLoaded === false) {
      Promise.all([
        listMenus(),
        getQueues(),
        getAgents(),
        getFlows(),
        getModules(),
        getPrompts(),
        listQueueTreatments(),
        getConnectPrompts(),
      ]);
    }
  }, [api]);

  useEffect(() => {
    if (state.flowsLoaded) {
      const customerQueueFlows = state.flows.filter(
        (flow: ContactFlowSummary) =>
          flow.ContactFlowType === 'CUSTOMER_QUEUE' && !VF_DEFAULT_QUEUE_FLOW_NAME_REGEX.test(flow.Name)
      );
      dispatch({
        type: 'SET_CUSTOMER_QUEUE_FLOWS',
        payload: customerQueueFlows,
      });
    }
  }, [state.flowsLoaded]);

  const resetUpsertHelpers = () => {
    setUpsertHelpers(prev => ({ ...initialUpserHelpersState, menuSearchTerm: prev.menuSearchTerm }));
  };

  const resetWorkingMenu = () => {
    const workingMenu = getInitialWorkingMenu();
    dispatch({ type: 'SET_WORKING_MENU', payload: workingMenu });
    dispatch({
      type: 'SET_CONNECT_LEX_BOTS',
      payload: {
        connectLexBots: [],
        allLexBotSummaries: [],
      },
    });
    resetUpsertHelpers();
  };

  const getQueueMetadata = (queueId: string) => {
    const queue = state.queues.find(queue => queue.Id === queueId);
    return queue;
  };
  const getEntityAttrValue = (
    type: ENTITY_TYPES,
    keyType: string,
    keyValue: string,
    returnAttr: string = null
  ): any => {
    const item = (state[type] as any[]).find(o => o[keyType] === keyValue);
    if (!item) return '';
    return returnAttr ? item[returnAttr] : item;
  };

  const getConnectPrompts = async () => {
    try {
      const prompts = await api.connect.listPrompts();
      setConnectPrompts(prompts);
      setUpsertHelpers(prev => ({ ...prev, successMessage: 'Connect prompts loaded successfully!' }));
    } catch (error) {
      setUpsertHelpers(prev => ({ ...prev, errorMessage: 'Failed to get Connect prompts' }));
    }
  };

  const listQueueTreatments = async () => {
    dispatch({ type: 'LOADING_QUEUE_TREATMENT', payload: true });
    const queueTreatmentList = await api.queueTreatment.listQueueFlowData();
    dispatch({
      type: 'LIST_QUEUE_TREATMENT',
      payload: queueTreatmentList,
    });
  };

  const listMenus = async () => {
    setUpsertHelpers(prev => ({ ...prev, openBackdrop: true, loadingMessage: 'Loading menus...' }));
    dispatch({ type: 'LOADING_MENUS', payload: true });
    try {
      const menuList = await api.menu.listMenus();
      dispatch({ type: 'LIST_MENUS', payload: menuList });
      setUpsertHelpers(prev => ({
        ...prev,
        openBackdrop: false,
        loadingMessage: '',
        successMessage: 'Menus loaded successfully!',
      }));
    } catch (error) {
      setUpsertHelpers(prev => ({
        ...prev,
        openBackdrop: false,
        loadingMessage: '',
        errorMessage: 'There was an error loading menus.',
      }));
    }
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: queues,
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: 'Queues loaded successfully!' }));
    } catch (err) {
      console.warn('Unable to load queues', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: 'Error loading queues.' }));
    }
  };
  const getAgents = async () => {
    try {
      const agentQueues = await api.helpers.getAgentQueues();
      dispatch({
        type: 'GET_AGENTS',
        payload: agentQueues,
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: 'Agent queues loaded successfully!' }));
    } catch (err) {
      console.warn('Unable to load agent queues', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: 'Error loading agent queues.' }));
    }
  };
  const getFlows = async () => {
    try {
      const flows = await api.helpers.getAllFlows();
      dispatch({
        type: 'GET_FLOWS',
        payload: flows,
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: 'Contact flows loaded!' }));
    } catch (err) {
      console.warn('Unable to load queues', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: 'Error loading contact flows.' }));
    }
  };

  const getModules = async () => {
    try {
      const modules = await api.helpers.getAllModules();
      dispatch({
        type: 'GET_MODULES',
        payload: modules,
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: 'Contact flow modules loaded!' }));
    } catch (err) {
      console.warn('Unable to load queues', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: 'Error loading contact flow modules.' }));
    }
  };

  const getPrompts = async () => {
    try {
      const prompts = await api.helpers.getAllPrompts();
      dispatch({
        type: 'GET_PROMPTS',
        payload: prompts,
      });
    } catch (err) {
      console.warn('Unable to load prompts', err);
    }
  };

  const getMenu = async (menuName: string) => {
    const workingMenu = await api.menu.getMenu(menuName);
    dispatch({ type: 'GET_MENU', payload: workingMenu });
  };

  const deleteMenu = async (menuName: string) => {
    dispatch({ type: 'DELETING_MENU', payload: true });
    setUpsertHelpers(prev => ({
      ...prev,
      openBackdrop: true,
      loadingMessage: `Deleting Menu: ${menuName}`,
    }));
    try {
      await api.menu.deleteMenu(menuName);
      dispatch({ type: 'DELETE_MENU', payload: { menuName } });
      setUpsertHelpers({
        ...initialUpserHelpersState,
        successMessage: `Menu ${menuName} was deleted successfully!`,
      });
      await listMenus();
    } catch (err) {
      dispatch({ type: 'DELETING_MENU', payload: false });
      setUpsertHelpers({
        ...initialUpserHelpersState,
        errorMessage: `Error deleting ${menuName}.`,
      });
    }
  };
  const upsertMenu = (menu: Menu) => {
    // @todo CALL API HERE
    return dispatch({ type: 'UPSERT_MENU', payload: { menu } });
  };

  const createMenuScaffolding = async (menu: Menu) => {
    await api.menu.createMenu(menu);
    await listMenus();
  };

  const setWorkingMenu = (menu: Menu) => {
    dispatch({ type: 'SET_WORKING_MENU', payload: { ...getInitialWorkingMenu(), ...menu } }); // spreading in model menu for backwards compatibility
  };

  const setConnectLexBots = (ompListBotsData: OMPListBotsData) => {
    dispatch({
      type: 'SET_CONNECT_LEX_BOTS',
      payload: {
        connectLexBots: ompListBotsData.connectLexBots,
        allLexBotSummaries: ompListBotsData.allLexBotSummaries,
      },
    });
  };

  const setWorkingConnectLexBot = (workingConnectLexBot: LexMenuOptions) => {
    dispatch({ type: 'SET_WORKING_CONNECT_LEX_BOT', payload: workingConnectLexBot });
  };

  const createMenu = async (noReset: boolean = false) => {
    setUpsertHelpers(prev => ({
      ...prev,
      openBackdrop: true,
      loadingMessage: 'Saving menu...',
    }));
    try {
      await api.menu.createMenu(state.workingMenu);
      setUpsertHelpers(prev => ({
        ...prev,
        open: false,
        loadingMessage: '',
        successMessage: `Menu: ${state.workingMenu.key} ${prev.upsertAction.toLowerCase()}d!`,
      }));
      await listMenus();
    } catch (err) {
      setUpsertHelpers(prev => ({
        ...prev,
        openBackdrop: false,
        loadingMessage: '',
        errorMessage: `Error ${prev.upsertAction === 'CREATE' ? 'creating' : 'updating'} menu`,
      }));
    }
    if (noReset) return;
    resetWorkingMenu();
  };

  return {
    ...state,
    menuValidators,
    upsertHelpers,
    connectPrompts,
    pollyWorkingLanguagePrompt,
    setPollyWorkingLanguagePrompt,
    setUpsertHelpers,
    upsertMenu,
    getMenu,
    listMenus,
    deleteMenu,
    getQueueMetadata,
    getEntityAttrValue,
    createMenuScaffolding,
    setWorkingMenu,
    resetWorkingMenu,
    resetUpsertHelpers,
    setConnectLexBots,
    setWorkingConnectLexBot,
    createMenu,
  };
};

export type UseMenuManagementType = ReturnType<typeof useMenuManagement>;
// Context
export const MenuManagementContext = createContext<UseMenuManagementType | null>(null);
export const useMenuManagementContext = () => useContext(MenuManagementContext)!; //<<< Don't forget the "!"

export const MenuManagementContextProvider = ({ children }: { children: ReactNode }) => (
  <MenuManagementContext.Provider value={useMenuManagement()}>{children}</MenuManagementContext.Provider>
);
