import { createTheme, useTheme, Breakpoint } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { find, isNull, map, merge } from 'lodash';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { VFTheme } from '@vf/theme/types';

import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

extend(utc);
extend(relativeTime);

type BreakpointOrNull = Breakpoint | null;

export const isBreakPointDown = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const defaultTheme = createTheme();
  return defaultTheme.breakpoints.values[key] > window.innerWidth;
};

export const useDownBreakPointChecker = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  return useMediaQuery((theme: VFTheme) => theme.breakpoints.down(key));
};

export const useBreakPointDown = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
};

export const useWidth = () => {
  const theme: VFTheme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export const getBreakPointsValue = (valueSet: any, breakpoint: string) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
  }
};

export const getFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getCustomDateTime = (value = 0, unit = 'days', format = 'YYYY-MM-DD'): string => {
  if (value === 0) {
    return dayjs().format(format);
  } else {
    // @ts-ignore
    return dayjs().add(value, unit).format(format);
  }
};

export const timeFromNow = (date: string) => {
  const timestamp = +dayjs(date).format('X');
  const newDate = dayjs.unix(timestamp);
  return dayjs(newDate).fromNow();
};

export const checkPermission = (routeAuth: string[] | null | undefined, userRole: any | null | undefined) => {
  if (!routeAuth || !routeAuth.length) {
    return true;
  }

  if (userRole && Array.isArray(userRole)) {
    return routeAuth.some((r: any) => userRole.indexOf(r) >= 0);
  }

  if (routeAuth.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
    return routeAuth.some(r => userRole.indexOf(r) >= 0);
  }
  return routeAuth.indexOf(userRole) >= 0;
};

export const vfMerge = (arr1, arr2) => map(arr1, item => merge(find(arr2, { field: item.field }), item));
export const ifNullThenDash = (params: GridValueGetterParams) => {
  return !isNull(params.value) ? params.value : '--';
};

export const getCurrentTimeDifference = (startTime: string) => {
  const now = dayjs();
  const then = dayjs(startTime);
  const diff = now.diff(then, 'seconds');
  const dateFromDiff = dayjs().startOf('day').add(diff, 'seconds');
  const format = dateFromDiff.utc().format('HH:mm:ss');
  return format;
};
