import { ICallDirection, PhoneNumberCategoryType, PhoneNumberType } from './models';

/**
 * @description This describes all the available api endpoints. Each endpoint might have GET or POST requests. Check the api lambda for more details
 */
export enum PhTaxonomyApiEndpoints {
  details = '/api/phone-number-taxonomy/details',
  deleteDetail = '/api/phone-number-taxonomy/details/{phoneNumberId}',
  categories = '/api/phone-number-taxonomy/categories',
  deleteCategory = '/api/phone-number-taxonomy/categories/{category}',
}

/**
 * @description This describes the standard error response from all api requests dealing with taxonomy
 */
export interface PhoneNumberTaxonomyApiError {
  error?: string;
}

/**
 * @description This describes the response from the /list api response
 */
export interface GetAllPhoneNumbersResponse extends PhoneNumberTaxonomyApiError {
  phoneNumbers: PhoneNumberType[];
}

/**
 * @description This describes the response from the /categories api request
 */
export interface GetAllPhoneNumberCategoriesResponse extends PhoneNumberTaxonomyApiError {
  categories: PhoneNumberCategoryType[];
}

/**
 * @description This describes the base model for a put request
 */
export interface PutRequest {
  phoneNumberId: string;
}

/**
 * @description This describes the parameters for associating a category to a phone number. This will replace the current association.
 */
export interface ReplaceCategoryRequest extends PutRequest {
  category: PhoneNumberCategoryType;
}

/**
 * @description This describes the parameters for changing the description of a phone number.
 */
export interface ReplaceDescriptionRequest extends PutRequest {
  description: string;
}

/**
 * @description This describes the different types of PUT requests that you can make to the /details endpoint
 */
export type PutDetailsRequestType = 'ReplaceCategory' | 'ReplaceDescription';

/**
 * @description This describes the parameters for a PUT request to the /details endpoint
 */
export interface PutDetailsRequest {
  UpdateAll?: EditPhoneNumberRequest;
  ReplaceCategory?: ReplaceCategoryRequest;
}

/**
 * @description This describes the response of of a PUT request to the /details endpoint
 */
export interface PutDetailsResponse extends PhoneNumberTaxonomyApiError {
  phoneNumber: PhoneNumberType;
}

/**
 * @description This describes the parameters to create a new phone number
 */
export interface CreatePhoneNumberRequest {
  phoneNumber: string;
  category: PhoneNumberCategoryType;
  description: string;
  direction: ICallDirection;
  disconnectOnMatch?: boolean | undefined;
}

/**
 * @description This describes the response from creating a phone number
 */
export interface CreatePhoneNumberResponse extends PhoneNumberType, PhoneNumberTaxonomyApiError {}

/**
 * @description This describes the parameters to edit a phone number
 */
export interface EditPhoneNumberRequest extends PhoneNumberType, PhoneNumberTaxonomyApiError {}

/**
 * @description This describes the response from editing a phone number
 */
export interface EditPhoneNumberResponse extends PhoneNumberType, PhoneNumberTaxonomyApiError {}

/**
 * @description This describes the request for deleting a phone number
 */
export interface DeletePhoneNumberRequest {
  phoneNumberId: string;
}

/**
 * @description This describes the response from deleting a phone number
 */
export interface DeletePhoneNumberResponse extends PhoneNumberTaxonomyApiError {
  phoneNumberId: string;
}

/**
 * @description This describes the request for deleting a single category
 */
export interface DeleteCategoryRequest {
  category: PhoneNumberCategoryType;
}

/**
 * @description this describes the response from deleting a single category
 */
export interface DeleteCategoryResponse extends PhoneNumberTaxonomyApiError {
  category: PhoneNumberCategoryType;
}

/**
 * @description This describes the request for creating a category
 */
export interface CreateCategoryRequest {
  category: PhoneNumberCategoryType;
}

/**
 * @description this describes the response from creating a category
 */
export interface CreateCategoryResponse extends PhoneNumberTaxonomyApiError {
  category: PhoneNumberCategoryType;
}
