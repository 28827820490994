import { ApiClientBase } from './ApiClientBase';
import { IMenuManagementApi, Menu, MenuList } from '@vf-omp/shared';
import { getLogger } from '@vf/services/LoggingService';

export class MenuManagementApiClient extends ApiClientBase implements IMenuManagementApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('MenuManagementApiClient'), useAuth);
  }

  async listMenus(): Promise<MenuList> {
    const result = await this.get(`/api/menu-management`);
    return await result.json();
  }

  async getMenu(menuKey: string): Promise<Menu> {
    const result = await this.get(`/api/menu-management/${menuKey}`);
    return await result.json();
  }
  async updateMenu(menu: Menu): Promise<Menu> {
    const result = await this.put(`/api/menu-management/${menu.key}`, menu);
    return await result.json();
  }
  async createMenu(menu: Menu): Promise<Menu> {
    const result = await this.post(`/api/menu-management/${menu.key}`, menu);
    return await result.json();
  }
  async deleteMenu(menuKey: string): Promise<void> {
    await this.delete(`/api/menu-management/${menuKey}`);
  }
}
