import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ErrorRoutes } from 'modules/errorPages';
import { HelpRoutes } from 'modules/helpPages';
import { AdminRoutes } from 'modules/administrators';
import { concat } from 'lodash';
import { checkPermission } from '../@vf/utility/Utils';
import { useEffect, useState } from 'react';
import DynamicPage from './administrators/Pages/DynamicPage';
import { MenuPlugin } from '@vf-omp/shared';
import { AuthUser } from 'types/models/AuthUser';
import { useAppContext } from '@vf/utility/ContextProvider';

export interface iNavItemProps {
  route?: React.ReactElement<any, any>;
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: iNavItemProps[];
}

export const parseAuthorizedRoutes = async (
  plugins: MenuPlugin[],
  user: AuthUser,
  flat: boolean = false
): Promise<iNavItemProps[]> => {
  const mapRoute: (item: MenuPlugin) => iNavItemProps = a => {
    return {
      id: a.id,
      auth: a.auth,
      icon: a.icon,
      title: a.title,
      messageId: a.messageId,
      type: a.type,
      url: a.url,
      children: a.children?.map(b => mapRoute(b)),
      route:
        a.route ?
          <Route key={a.route.key} path={a.route.path} element={<DynamicPage src={a.route.src} />} />
        : undefined,
    };
  };
  const pluginRoutes = plugins.map<iNavItemProps>(a => mapRoute(a));
  const fullRouteList = concat(AdminRoutes, pluginRoutes, HelpRoutes, ErrorRoutes);

  /**
   * Filters a given list of routes based on user access and the provided auth
   * abstracted to allow us to recursively run it
   */
  const filterRoutes = (rawRouteList: iNavItemProps[]): iNavItemProps[] => {
    return rawRouteList.reduce((a, c) => {
      const { auth, children } = c;
      const userClaims = user ? user.groups : [];
      const enableRoute = checkPermission(auth, userClaims);
      if (enableRoute) {
        if (children && children.length) {
          // If the flat flag is provided, add children to the base list
          if (flat) {
            const childRoutes = filterRoutes(c.children).map(_r => {
              if (!_r.route) {
                _r.route = c.route;
              }
              return _r;
            });
            return concat(a, childRoutes);
          } else {
            c.children = filterRoutes(c.children);
          }
        }

        a.push(c);
      }
      return a;
    }, []);
  };

  return filterRoutes(fullRouteList);
};

const AppRoutes: React.FC = () => {
  const { user, config } = useAppContext();
  const [routes, setRoutes] = useState<React.ReactElement<any, any>[]>([]);

  useEffect(() => {
    (async () => {
      /**
       * Fetch only the authorized routes.. flattened
       */
      const filteredRouteList = (await parseAuthorizedRoutes(config.plugins, user, true))
        .filter(o => !!o.route)
        .map(o => o.route);
      console.log(filteredRouteList);
      setRoutes(filteredRouteList);
    })();
  }, []);

  return <Routes>{routes}</Routes>;
};

export default AppRoutes;
