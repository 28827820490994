import { ClosuresState } from './ManagementContextPropTypes';

const config: ClosuresState = {
  closuresList: [],
  closuresLoaded: false,
  loadingClosures: false,
  deletingClosures: false,
  prompts: [],
  promptsLoaded: false,
  loadingPrompts: false,
  queues: [],
  queuesLoaded: false,
  numbers: [],
  numbersLoaded: false,
  timezones: [],
  timezonesLoaded: false,
  error: '',
  msg: undefined,
  connectPrompts: [],
};

export default config;
