import ConnectUserManagementContextPropsType from './ManagementContextPropTypes';

const config: ConnectUserManagementContextPropsType = {
  connectUsers: [],
  connectUsersLoaded: false,
  routingProfiles: [],
  routingProfilesLoaded: false,
  routingProfileDict: {},
  userHierarchies: [],
  userHierarchiesLoaded: false,
  userHierarchyDict: {},
  securityProfiles: [],
  securityProfilesLoaded: false,
  securityProfileDict: {},
};

export default config;
