import React, { useCallback, useContext, useEffect, useReducer, useState, createContext, ReactNode } from 'react';
import { contextReducer } from './ContextReducer';
import { pollyLanguageList } from './pollyLanguageList';
import RFC5646_LANGUAGE_MAP from 'shared/constants/RFC5646_LANGUAGE_MAP';
import ConfigurationService from '../../services/ConfigurationService';
import AppContextStateType from './AppContextStateType';

const buildContextState = (): AppContextStateType => {
  return {
    user: null,
    tenancyEnabled: false,
    tenancyOn: false,
    featureList: [],
    initialPath: '',
    config: null,
  };
};

const useGlobals = () => {
  const [configIsLoaded, setConfigIsLoaded] = useState<boolean>(false);

  const [state, dispatch] = useReducer(contextReducer, buildContextState());

  useEffect(() => {
    if (configIsLoaded) return;
    const config: ConfigurationService = new ConfigurationService();
    const initializeConfig = async () => {
      await config.load();
      setConfig(config);
      setConfigIsLoaded(true);
    };
    initializeConfig();
  }, []);

  useEffect(() => {
    console.log('Features ', state.featureList);
  }, [state.featureList]);

  const setConfig = (config: ConfigurationService) => {
    dispatch({
      type: 'SET_DEFAULT_GLOBAL_CONFIG',
      payload: config,
    });
  };
  const updateAuthUser = useCallback(user => {
    dispatch({ type: 'UPDATE_AUTH_USER', payload: user });
  }, []);

  const setInitialPath = useCallback(path => {
    dispatch({ type: 'SET_INITIAL_PATH', payload: path });
  }, []);

  const enableTenancy = useCallback((bool: boolean) => {
    dispatch({ type: 'ENABLE_TENANCY', payload: bool });
  }, []);
  const setTenancySetting = useCallback((bool: boolean) => {
    dispatch({ type: 'SET_TENANCY_SETTING', payload: bool });
  }, []);
  const setAvailableFeatures = (featureList: string[]) => {
    dispatch({
      type: 'SET_AVAILABLE_FEATURES',
      payload: featureList,
    });
  };

  const checkFeatureAccess = (feature: string) => state.featureList.includes(feature);

  const getDisplayLang = (code: string) => {
    return RFC5646_LANGUAGE_MAP[code] || code;
  };

  return {
    ...state,
    pollyLanguageList,
    RFC5646_LANGUAGE_MAP,
    getDisplayLang,
    setInitialPath,
    updateAuthUser,
    enableTenancy,
    setTenancySetting,
    setAvailableFeatures,
    checkFeatureAccess,
  };
};

export type UseGlobalsType = ReturnType<typeof useGlobals>;
export const GlobalsContext = createContext<UseGlobalsType | null>(null);
export const useAppContext = () => useContext(GlobalsContext)!; // <-- don't forget the '!'
const ContextProvider = ({ children }: { children: ReactNode }) => (
  <GlobalsContext.Provider value={useGlobals()}>{children}</GlobalsContext.Provider>
);
export default ContextProvider;
