import UserManagementContextPropsType from './ManagementContextPropTypes';

const config: UserManagementContextPropsType = {
  loadingData: false,
  appUsers: [],
  appUsersLoaded: false,
  userNames: [],
  connectUserKeys: [],
  connectQueueKeys: [],
  connectQueues: [],
  connectNumberKeys: [],
  connectNumbers: [],
  connectPromptKeys: [],
  connectPrompts: [],
  connectRoutingProfileKeys: [],
  connectRoutingProfiles: [],
  connectSecurityProfileKeys: [],
  connectSecurityProfiles: [],
  connectContactFlowKeys: [],
  connectContactFlows: [],
  connectHierarchyGroupKeys: [],
  connectHierarchyGroups: [],
  connectHierarchyStructure: null,
  connectDetailedHierarchies: null,
  connectUsersLoaded: false,
  roles: [],
  roleKeys: [],
  rolesLoaded: false,
  roleDetail: null,
  roleDetailLoaded: false,
  policies: [],
  policyKeys: [],
  policyDetail: null,
  policyDetailLoaded: false,
  policiesLoaded: false,
  policySupplementalLoaded: false,
  defaultPolicy: null,
  error: '',
  msg: null,
};

export default config;
