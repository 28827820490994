import { getLogger } from '@vf/services/LoggingService';
import { IExtension, IExtensionDialingApi } from '@vf-omp/shared';
import { ApiClientBase } from './ApiClientBase';

export class ExtensionDialingApiClient extends ApiClientBase implements IExtensionDialingApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('ExtensionDialingApiClient'), useAuth);
  }

  async listExtensions(): Promise<IExtension[]> {
    const result = await this.get('/api/extensionDialing');
    return await result.json();
  }
  async putExtension(model: IExtension): Promise<void> {
    await this.put('/api/extensionDialing', model);
  }
  async deleteExtension(id: string): Promise<void> {
    await this.delete(`/api/extensionDialing/${id}`);
  }
}
