import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import config from './config';
import { IMessage } from 'types/models/IMessage';
import { reducer } from './reducer';
import { PromptSummary } from '@aws-sdk/client-connect';
import { ENTITY_TYPES, getEntityAttrValueHelper } from 'contexts/SharedContextModels';
export const INITIAL_STATE = {
  ...config,
};

const useClosures = () => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [connectPrompts, setConnectPrompts] = useState<PromptSummary[]>([]);

  useEffect(() => {
    listClosures();
    listClosurePrompts();
    getQueues();
    getNumbers();
    getTimezones();
    getConnectPrompts();
  }, [api]);

  const getQueueMetadata = (queueId: string) => {
    const queue = state.queues.find(queue => queue.Id === queueId);
    return queue;
  };

  const getConnectPrompts = async () => {
    try {
      const prompts = await api.connect.listPrompts();
      setConnectPrompts(prompts);
    } catch (error) {
      console.log(error);
    }
  };

  const listClosures = async () => {
    dispatch({ type: 'LOADING_CLOSURES', payload: true });
    try {
      const closuresList = await api.closure.getAllClosures();
      dispatch({ type: 'LIST', payload: { closuresList } });
    } catch (err) {
      dispatch({ type: 'LOADING_CLOSURES', payload: false });
      dispatch({
        type: 'SET_ERROR',
        payload: { error: 'Server error while loading closures data' },
      });
    }
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: {
          queues,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getNumbers = async () => {
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: {
          numbers,
        },
      });
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
    }
  };
  const getTimezones = async () => {
    try {
      const timezones = await api.helpers.getAllTimezones();
      dispatch({
        type: 'GET_TIMEZONES',
        payload: {
          timezones,
        },
      });
    } catch (err) {
      console.warn('Unable to load timezones', err);
    }
  };
  const listClosurePrompts = async () => {
    dispatch({ type: 'LOADING_PROMPTS', payload: true });
    try {
      const prompts = await api.closure.getClosurePrompts();
      dispatch({
        type: 'LIST_PROMPTS',
        payload: { prompts: prompts.prompts },
      });
    } catch (err) {
      dispatch({ type: 'LOADING_PROMPTS', payload: false });
      setMsg({
        message: 'Server error while loading prompts data',
        type: 'error',
      });
    }
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: { msg } });
  };

  const getEntityAttrValue = (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr: string = null) =>
    getEntityAttrValueHelper(state, type, keyType, keyValue, returnAttr);

  return {
    ...state,
    connectPrompts,
    getQueueMetadata,
    setMsg,
    getEntityAttrValue,
    listClosures,
    listClosurePrompts,
  };
};

export type UseClosuresType = ReturnType<typeof useClosures>;
// Context
export const ClosuresContext = createContext<UseClosuresType | null>(null);
export const useClosuresContext = () => useContext(ClosuresContext)!;

export const ClosuresManagementContextProvider = ({ children }: { children: ReactNode }) => (
  <ClosuresContext.Provider value={useClosures()}>{children}</ClosuresContext.Provider>
);
