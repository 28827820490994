import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { ContactFlowSummary } from '@aws-sdk/client-connect';
import { IMessage } from 'types/models/IMessage';
import initialState, { initialWorkingEntryPoint } from './ContactEntryPointsConfig';
import { entryPointReducer } from './entryPointReducer';
import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { EntryFlowData, Menu, OpenCheckTypes } from '@vf-omp/shared';
import { VF_DEFAULT_QUEUE_FLOW_NAME_REGEX } from '../../defaultUtils/regexUtils';
import {
  getLoadingMessage,
  getLoadSuccessMessage,
  getLoadFailedMessage,
  getUpdateFailedMessage,
  getUpdateSuccessMessage,
  getCreateFailedMessage,
  getCreateSuccessMessage,
  getDeleteFailedMessage,
  getDeleteSuccessMessage,
  getSavingMessage,
  getDeletingMessage,
} from 'shared/constants/CRUD_Messages';

export enum ENTITY_TYPES {
  FLOWS = 'flows',
  CUSTOMER_QUEUE_FLOWS = 'customerQueueFlows',
  QUEUES = 'queues',
  PROMPTS = 'prompts',
}
export type UpsertEntryPointSteps = 'BASIC_CONFIG' | 'FALLBACK_CONFIG' | 'OPEN' | 'CLOSED' | 'HOLIDAY' | 'CLOSURE';
export type UpsertActionsType = 'CREATE' | 'UPDATE' | 'DELETE' | 'NONE';
export interface UpsertHelpers {
  upsertAction: UpsertActionsType;
  currentStep: UpsertEntryPointSteps;
  openBackdrop: boolean;
  loadingMessage: string;
  errorMessage: string;
  warningMessage: string;
  successMessage: string;
  infoMessage: string;
}

const initialUpserHelpersState: UpsertHelpers = {
  upsertAction: 'NONE',
  currentStep: undefined,
  openBackdrop: false,
  loadingMessage: '',
  errorMessage: '',
  successMessage: '',
  warningMessage: '',
  infoMessage: '',
};

const useEntryPointManagement = () => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(entryPointReducer, initialState);
  const { entryPointsLoaded, entryPointList } = state;
  const [upsertHelpers, setUpsertHelpers] = useState<UpsertHelpers>(initialUpserHelpersState);

  useEffect(() => {
    const loadAll = async () => {
      setUpsertHelpers(prev => ({
        ...prev,
        loadingMessage: getLoadingMessage('Entry point data'),
        openBackdrop: true,
      }));
      await Promise.all([
        listEntryPoints(),
        getQueues(),
        getAgents(),
        getFlows(),
        getPrompts(),
        getAvailableHours(),
        listMenus(),
        getNumbers(),
        listQueueTreatments(),
      ]);
      setUpsertHelpers(prev => ({ ...prev, loadingMessage: '', openBackdrop: false }));
    };
    if (entryPointsLoaded === false && entryPointList.length === 0) {
      loadAll();
    }
  }, [api]);

  useEffect(() => {
    if (state.numbersLoaded && state.entryPointsLoaded) {
      const availNumbers = state.numbers.filter(n => !state.entryPointList.map(n => n.key).includes(n.PhoneNumber));
      dispatch({
        type: 'SET_AVAILABLE_NUMBERS',
        payload: availNumbers,
      });
    }
  }, [state.numbers, state.numbersLoaded, state.entryPointList, state.entryPointsLoaded]);

  useEffect(() => {
    if (state.availableHoursLoaded) {
      const customHours = state.availableHours.filter(n => n.openCheckType === OpenCheckTypes.CUSTOM);
      dispatch({
        type: 'SET_AVAILABLE_CUSTOM_HOURS',
        payload: customHours,
      });
    }
  }, [state.availableHours, state.availableHoursLoaded]);
  useEffect(() => {
    if (state.flowsLoaded) {
      const customerQueueFlows = state.flows.filter(
        (flow: ContactFlowSummary) =>
          flow.ContactFlowType === 'CUSTOMER_QUEUE' && !VF_DEFAULT_QUEUE_FLOW_NAME_REGEX.test(flow.Name)
      );
      dispatch({
        type: 'SET_CUSTOMER_QUEUE_FLOWS',
        payload: { customerQueueFlows },
      });
    }
  }, [state.flowsLoaded]);

  const getQueueMetadata = (queueId: string) => {
    const queue = state.queues.find(queue => queue.Id === queueId);
    return queue;
  };
  const getEntityAttrValue = (
    type: ENTITY_TYPES,
    keyType: string,
    keyValue: string,
    returnAttr: string = null
  ): any => {
    const item = (state[type] as any[]).find(o => o[keyType] === keyValue);
    if (!item) return '';
    return returnAttr ? item[returnAttr] : item;
  };

  const listMenus = async () => {
    const entity = 'Menus';
    try {
      const menuList = await api.menu.listMenus();
      dispatch({ type: 'LIST_MENUS', payload: { menuList } });
      setUpsertHelpers(prev => ({ ...prev, successMessage: getLoadSuccessMessage(entity) }));
    } catch (error) {
      console.warn(`Unable to load menus. ${error}`);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getLoadFailedMessage(entity) }));
    }
  };

  const setWorkingEntryPoint = (entryPoint: EntryFlowData) => {
    dispatch({
      type: 'SET_WORKING_ENTRY_POINT',
      payload: { workingEntryPoint: { ...initialWorkingEntryPoint, ...entryPoint } }, // spreading in model working entrypoint for backwards compatibility
    });
  };

  const resetWorkingEntryPoint = () => {
    setWorkingEntryPoint(initialWorkingEntryPoint);
  };

  const listEntryPoints = async () => {
    const entity = 'Entry point data';
    setUpsertHelpers(prev => ({ ...prev, loadingMessage: getLoadingMessage(entity), openBackdrop: true }));
    try {
      const entryPointList = await api.entryPoint.listEntryPointData();
      dispatch({
        type: 'LIST_ENTRY_POINTS',
        payload: { entryPointList },
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: getLoadSuccessMessage(entity) }));
    } catch (error) {
      console.warn(`Unable to load entry points. ${error}`);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getLoadFailedMessage(entity) }));
    }
    setUpsertHelpers(prev => ({ ...prev, loadingMessage: '', openBackdrop: false }));
  };

  const listQueueTreatments = async () => {
    const entity = 'Queue treatments';
    try {
      const queueTreatmentList = await api.queueTreatment.listQueueFlowData();
      dispatch({
        type: 'LIST_QUEUE_TREATMENT',
        payload: { queueTreatmentList },
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: getLoadSuccessMessage(entity) }));
    } catch (error) {
      console.warn(`Unable to load ${entity}. ${error}`);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getLoadFailedMessage(entity) }));
    }
  };
  const createMenuScaffolding = async (menu: Menu) => {
    const entity = 'Menu scaffolding';
    try {
      await api.menu.createMenu(menu);
      await listMenus();
      setUpsertHelpers(prev => ({ ...prev, successMessage: getCreateSuccessMessage(entity) }));
    } catch (error) {
      const message = getCreateFailedMessage(entity);
      console.warn(`${message} ${error}`);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: message }));
    }
  };

  const getNumbers = async () => {
    const entity = 'Phone numbers';
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: {
          numbers,
        },
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: getLoadSuccessMessage(entity) }));
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getLoadFailedMessage(entity) }));
    }
  };

  const getQueues = async () => {
    const entity = 'Queues';
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: {
          queues,
        },
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: getLoadSuccessMessage(entity) }));
    } catch (err) {
      console.warn('Unable to load queues', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getLoadFailedMessage(entity) }));
    }
  };
  const getAgents = async () => {
    const entity = 'Agents';
    try {
      const agentQueues = await api.helpers.getAgentQueues();
      dispatch({
        type: 'GET_AGENTS',
        payload: {
          agentQueues,
        },
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: getLoadSuccessMessage(entity) }));
    } catch (err) {
      console.warn('Unable to load agent queues', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getLoadFailedMessage(entity) }));
    }
  };
  const getAvailableHours = async () => {
    const entity = 'Hours';
    try {
      const availableHours = await api.hour.getAvailableHoursSelections();
      dispatch({
        type: 'GET_AVAILABLE_HOURS',
        payload: {
          availableHours,
        },
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: getLoadSuccessMessage(entity) }));
    } catch (err) {
      console.warn('Unable to load available hours', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getLoadFailedMessage(entity) }));
    }
  };
  const getFlows = async () => {
    const entity = 'Flows';
    try {
      const flows = await api.helpers.getAllFlows();
      dispatch({
        type: 'GET_FLOWS',
        payload: {
          flows,
        },
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: getLoadSuccessMessage(entity) }));
    } catch (err) {
      console.warn('Unable to load queues', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getLoadFailedMessage(entity) }));
    }
  };
  const getPrompts = async () => {
    const entity = 'Prompts';
    try {
      const prompts = await api.helpers.getAllPrompts();
      dispatch({
        type: 'GET_PROMPTS',
        payload: {
          prompts,
        },
      });
      setUpsertHelpers(prev => ({ ...prev, successMessage: getLoadSuccessMessage(entity) }));
    } catch (err) {
      console.warn('Unable to load prompts', err);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getLoadFailedMessage(entity) }));
    }
  };
  const getEntryPoint = async (key: string) => {
    const workingEntryPoint = await api.entryPoint.getEntryPointData(key);
    dispatch({ type: 'GET_ENTRY_POINT', payload: { workingEntryPoint } });
  };
  const deleteEntryPoint = async (key: string) => {
    const entity = 'Entry point';
    setUpsertHelpers(prev => ({ ...prev, loadingMessage: getDeletingMessage(entity), openBackdrop: true }));
    try {
      await api.entryPoint.deleteEntryPointData(key);
      await listEntryPoints();
      setUpsertHelpers(prev => ({ ...prev, successMessage: getDeleteSuccessMessage(entity) }));
    } catch (err) {
      console.warn(`Error deleting entry point:\n${err}`);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getDeleteFailedMessage(entity) }));
    }

    setUpsertHelpers(prev => ({ ...prev, loadingMessage: '', openBackdrop: false }));
  };
  const upsertEntryPoint = async (entryPoint: EntryFlowData, noReset: boolean = false) => {
    const entity = 'Entry Point';
    // @todo CALL API HERE
    setUpsertHelpers(prev => ({ ...prev, loadingMessage: getSavingMessage(entity), openBackdrop: true }));
    try {
      const result = await api.entryPoint.updateEntryPointData({ ...entryPoint, id: 'entry' });
      dispatch({ type: 'UPSERT_ENTRY_POINT', payload: { entryPoint: result } });
      setUpsertHelpers(prev => ({
        ...prev,
        successMessage: getUpdateSuccessMessage(entity),
      }));
    } catch (error) {
      console.warn('Unable to update entry point', error);
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getUpdateFailedMessage(entity) }));
    }
    setUpsertHelpers(prev => ({ ...prev, loadingMessage: '', openBackdrop: false }));
    if (noReset) return;
    resetWorkingEntryPoint();
  };

  const createEntryPointScaffolding = async (entryPoint: EntryFlowData) => {
    await api.entryPoint.createEntryPointData(entryPoint);
    await listEntryPoints();
  };

  const setMsg = (message: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: { message } });
  };

  const setDefaultLang = (lang: string) => {
    dispatch({ type: 'SET_DEFAULT_LANG', payload: { lang } });
  };

  return {
    ...state,
    upsertHelpers,
    setUpsertHelpers,
    resetWorkingEntryPoint,
    setWorkingEntryPoint,
    upsertEntryPoint,
    getEntryPoint,
    listEntryPoints,
    deleteEntryPoint,
    getQueueMetadata,
    getEntityAttrValue,
    createEntryPointScaffolding,
    createMenuScaffolding,
    setMsg,
    setDefaultLang,
  };
};

export type UseEntryPointManagementType = ReturnType<typeof useEntryPointManagement>;

export const EntryPointManagementContext = createContext<UseEntryPointManagementType | null>(null);
export const useEntryPointManagementContext = () => useContext(EntryPointManagementContext)!;

export const EntryPointManagementContextProvider = ({ children }: { children: ReactNode }) => (
  <EntryPointManagementContext.Provider value={useEntryPointManagement()}>
    {children}
  </EntryPointManagementContext.Provider>
);
