import { AuthUser } from '../../types/models/AuthUser';
export const ADMIN_GROUP = 'omp-admin';

export const authRole = {
  admin: [ADMIN_GROUP],
};

export const defaultUser: AuthUser = {
  email: 'demo@example.com',
  username: 'tester@testing.com',
  userType: 'admin',
  groups: authRole.admin,
};
export const initialUrl = '/'; // this url will open after login

export const TITLE = 'Operations Management Portal';
