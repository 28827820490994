import { VFThemeOptions } from './types';
import { vfDefaultTheme } from '@voicefoundry-cloud/vf-storybook/dist/themes/vf-default-theme';
import { merge } from 'lodash';

/**
 * Implemented as a function to allow custom color params
 */
export const vfThemeOptions = (): VFThemeOptions => {
  const customTheme = {
    // Add customizations here
    // NOTE: It's better to do in vf-storybook so that all apps pick up the change
  };
  // VF Default styling inherited from storybook
  return merge(vfDefaultTheme, customTheme);
};
