import { lowerCase, upperFirst } from 'lodash';

const getFirstWord = (word: string, ignoreCase?: boolean) => (ignoreCase ? word : upperFirst(lowerCase(word)));

export const getLoadingMessage = (entity: string, ignoreCase?: boolean) => {
  return `Loading${entity.length ? ' ' : ''}${ignoreCase ? entity : lowerCase(entity)}...`;
};
export const getCreateSuccessMessage = (entity: string, ignoreCase?: boolean) => {
  return `${getFirstWord(entity, ignoreCase)} created successfully!`;
};

export const getCreateFailedMessage = (entity: string, ignoreCase?: boolean) => {
  return `${getFirstWord(entity, ignoreCase)} failed to create.`;
};

export const getSavingMessage = (entity: string, ignoreCase?: boolean) => {
  return `Saving${entity.length ? ' ' : ''} ${ignoreCase ? entity : lowerCase(entity)}...`;
};
export const getUpdateSuccessMessage = (entity: string, ignoreCase?: boolean) => {
  return `${getFirstWord(entity, ignoreCase)} saved successfully!`;
};

export const getUpdateFailedMessage = (entity: string, ignoreCase?: boolean) => {
  return `${getFirstWord(entity, ignoreCase)} failed to save.`;
};

export const getDeletingMessage = (entity: string, ignoreCase?: boolean) => {
  return `Deleting${entity.length ? ' ' : ''} ${ignoreCase ? entity : lowerCase(entity)}...`;
};

export const getDeleteSuccessMessage = (entity: string, ignoreCase?: boolean) => {
  return `${getFirstWord(entity, ignoreCase)} deleted successfully!`;
};

export const getDeleteFailedMessage = (entity: string, ignoreCase?: boolean) => {
  return `${getFirstWord(entity, ignoreCase)} failed to delete.`;
};

export const getLoadSuccessMessage = (entity: string, ignoreCase?: boolean) => {
  return `${getFirstWord(entity, ignoreCase)} loaded successfully!`;
};
export const getLoadFailedMessage = (entity: string, ignoreCase?: boolean) => {
  return `${getFirstWord(entity, ignoreCase)} failed to load.`;
};
