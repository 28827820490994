import { getLogger } from '@vf/services/LoggingService';
import React, { useEffect } from 'react';
import { VFSnackbar } from '@voicefoundry-cloud/vf-storybook';
import Loader from '../Loader';
import { fetchError, showMessage, useInfoViewActionsContext, useInfoViewContext } from './InfoViewContext';

const logger = getLogger('InfoView');

interface InfoViewProps {}

const InfoView: React.FC<InfoViewProps> = () => {
  logger.debug(`Initializing Provider`);
  const { error, loading, message } = useInfoViewContext();
  const dispatch = useInfoViewActionsContext()!;

  useEffect(() => {
    if (loading) {
      console.log('loading spinner');
    } else {
      console.log('No loading spinner');
    }
  }, [loading]);
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(fetchError(''));
      }, 6000);
    }
  }, [error]);
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(showMessage(''));
      }, 6000);
    }
  }, [message]);

  return (
    <>
      <VFSnackbar open={!!message} severity="success">
        {message.toString()}
      </VFSnackbar>
      <VFSnackbar open={!!error} severity="error">
        {error.toString()}
      </VFSnackbar>
      {loading && <Loader />}
    </>
  );
};

export default InfoView;
