import { findIndex } from 'lodash';
import { VFUser } from '@vf-omp/shared';
import UserManagementContextPropsType from './ManagementContextPropTypes';

type UserManagementAction =
  | 'ROUTING_PROFILES_LOADED'
  | 'SET_ROUTING_PROFILES'
  | 'SECURITY_PROFILES_LOADED'
  | 'SET_SECURITY_PROFILES'
  | 'USER_HIERARCHIES_LOADED'
  | 'SET_USER_HIERARCHIES'
  | 'CONNECT_USERS_LOADED'
  | 'SET_CONNECT_USERS'
  | 'UPDATE_USER_ROUTING_PROFILE';

export function reducer(
  state: any,
  action: { type: UserManagementAction; payload: any }
): UserManagementContextPropsType {
  switch (action.type) {
    case 'ROUTING_PROFILES_LOADED':
      return {
        ...state,
        routingProfilesLoaded: true,
      };
    case 'SET_ROUTING_PROFILES':
      return {
        ...state,
        routingProfiles: action.payload,
        routingProfileDict: action.payload.reduce(
          (acc, rp) => ({
            ...acc,
            [rp.Id]: rp,
          }),
          {}
        ),
      };
    case 'SECURITY_PROFILES_LOADED':
      return {
        ...state,
        securityProfilesLoaded: true,
      };
    case 'SET_SECURITY_PROFILES':
      return {
        ...state,
        securityProfiles: action.payload,
        securityProfileDict: action.payload.reduce(
          (acc, rp) => ({
            ...acc,
            [rp.Id]: rp,
          }),
          {}
        ),
      };
    case 'USER_HIERARCHIES_LOADED':
      return {
        ...state,
        userHierarchiesLoaded: true,
      };
    case 'SET_USER_HIERARCHIES':
      return {
        ...state,
        userHierarchies: action.payload,
        userHierarchyDict: action.payload.reduce(
          (acc, rp) => ({
            ...acc,
            [rp.Id]: rp,
          }),
          {}
        ),
      };
    case 'CONNECT_USERS_LOADED':
      return {
        ...state,
        connectUsersLoaded: true,
      };
    case 'SET_CONNECT_USERS':
      return {
        ...state,
        connectUsers: action.payload,
      };
    case 'UPDATE_USER_ROUTING_PROFILE': {
      const users: VFUser[] = state.connectUsers.slice();
      const idx = findIndex(users, user => user.Id === action.payload.userId);
      let user = state.connectUsers[idx];
      user = {
        ...user,
        RoutingProfileId: action.payload.routingProfileId,
      };
      users.splice(idx, 1, user);
      return {
        ...state,
        connectUsers: users,
      };
    }
    default:
      return state;
  }
}
