import { ApiClientBase } from './ApiClientBase';
import { ClosureApi, ClosurePromptVm, ClosureVm, PromptVm } from '@vf-omp/shared';
import { getLogger } from '@vf/services/LoggingService';

export class ClosuresApiClient extends ApiClientBase implements ClosureApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('ClosuresApiClient'), useAuth);
  }

  async deleteClosure(id: string): Promise<void> {
    await this.delete(`/api/closures/${id}`);
  }

  async deletePrompt(promptId: string): Promise<void> {
    await this.delete(`/api/closures/prompts/${promptId}`);
  }

  async getAllClosures(): Promise<ClosureVm[]> {
    const result = await this.get('/api/closures');
    return await result.json();
  }

  async getClosurePrompts(): Promise<ClosurePromptVm> {
    const result = await this.get('/api/closures/prompts');
    return await result.json();
  }

  async postClosure(closure: ClosureVm): Promise<ClosureVm> {
    const result = await this.post('/api/closures', closure);
    return await result.json();
  }

  async postPrompt(prompt: PromptVm): Promise<PromptVm> {
    const result = await this.post('/api/closures/prompts', prompt);
    return await result.json();
  }

  async putClosure(closure: ClosureVm): Promise<ClosureVm> {
    delete closure.permissions;
    const result = await this.put('/api/closures', closure);
    return await result.json();
  }

  async putPrompt(prompt: PromptVm): Promise<PromptVm> {
    const result = await this.put('/api/closures/prompts', prompt);
    return await result.json();
  }
}
