import { HolidaysState } from './HolidaysManagementContextPropTypes';

type HolidayManagementAction =
  | 'DELETE'
  | 'UPSERT'
  | 'GET'
  | 'LIST'
  | 'LIST_PROMPTS'
  | 'GET_QUEUES'
  | 'GET_NUMBERS'
  | 'GET_TIMEZONES'
  | 'LOADING_HOLIDAYS'
  | 'LOADING_PROMPTS'
  | 'DELETING'
  | 'SET_MESSAGE';

export function reducer(state: HolidaysState, action: { type: HolidayManagementAction; payload: any }): HolidaysState {
  switch (action.type) {
    case 'DELETE': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deleting: false,
      };
    }
    case 'DELETING': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deleting: action.payload,
      };
    }
    case 'UPSERT': {
      // @todo Potentially append menu in list instead of listing again
      return state;
    }
    case 'LOADING_HOLIDAYS': {
      return {
        ...state,
        loadingHolidays: action.payload,
      };
    }
    case 'LOADING_PROMPTS': {
      return {
        ...state,
        loadingPrompts: action.payload,
      };
    }
    case 'LIST': {
      return {
        ...state,
        holidaysLoaded: true,
        loadingHolidays: false,
        holidaysList: action.payload.holidaysList,
      };
    }
    case 'LIST_PROMPTS': {
      return {
        ...state,
        loadingPrompts: false,
        promptsLoaded: true,
        prompts: action.payload.prompts,
      };
    }
    case 'GET_QUEUES':
      return {
        ...state,
        queuesLoaded: true,
        queues: action.payload.queues,
      };
    case 'GET_NUMBERS':
      return {
        ...state,
        numbersLoaded: true,
        numbers: action.payload.numbers,
      };
    case 'GET_TIMEZONES':
      return {
        ...state,
        timezonesLoaded: true,
        timezones: action.payload.timezones,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
}
