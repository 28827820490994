import { OpenCheckTypes } from '@vf-omp/shared';
import { HoursManagementState } from './HoursManagementContextPropTypes';

const config: HoursManagementState = {
  hoursList: [],
  hoursLoaded: false,
  loadingHours: false,
  deletingHours: false,
  deletingMenu: false,
  queues: [],
  queuesLoaded: false,
  numbers: [],
  numbersLoaded: false,
  timezones: [],
  timezonesLoaded: false,
  error: '',
  msg: undefined,
  associationsObj: {
    [OpenCheckTypes.QUEUE]: [],
    [OpenCheckTypes.DNIS]: [],
    [OpenCheckTypes.CUSTOM]: [],
  },
};

export default config;
