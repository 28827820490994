import { AlertProps } from '@mui/material';

export interface CRUD_Helpers {
  openBackdrop: boolean;
  openAlert: boolean;
  openConfirmDeleteFlow: boolean;
  openConfirmDeletePrompt: boolean;
  openConfirmDeleteLanguage: boolean;
  openPromptLanguageDialog: boolean;
  deleteId: string;
  deleteName: string;
  deleteLang: string;
  confirmDeleteMessage: string;
  confirmDeleteTitle: string;
  backdropMessage: string;
  alertMessage: string;
  severity: AlertProps['severity'];
}

export const initialCrudHelpers: CRUD_Helpers = {
  openBackdrop: false,
  openAlert: false,
  openConfirmDeleteFlow: false,
  openPromptLanguageDialog: false,
  openConfirmDeletePrompt: false,
  openConfirmDeleteLanguage: false,
  deleteId: '',
  deleteName: '',
  deleteLang: '',
  confirmDeleteMessage: 'Are you sure you want to delete: ',
  confirmDeleteTitle: 'Delete?',
  backdropMessage: '',
  alertMessage: '',
  severity: 'success',
};
