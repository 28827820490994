import MetricsContextPropsType from './ContextPropTypes';

type MetricsAction =
  | 'SET_RT_QUEUE_METRICS_DATA'
  | 'SET_RT_AGENT_METRICS_DATA'
  | 'SET_RT_RP_METRICS_DATA'
  | 'SET_RT_QUEUE_METRICS_INTERVAL'
  | 'SET_RT_AGENT_METRICS_INTERVAL'
  | 'SET_RT_RP_METRICS_INTERVAL'
  | 'SET_AVAILABLE_CONNECT_USERS'
  | 'START_CONTACT_SEARCH_QUERY'
  | 'START_CONTACT_DETAIL_QUERY'
  | 'START_CONTACT_ATTRIBUTES_QUERY'
  | 'SET_CONTACT_SEARCH_QUERY_RESULTS'
  | 'SET_CONTACT_DETAIL'
  | 'SET_CONTACT_DETAIL_ATTRIBUTES'
  | 'SET_ERROR';

export function reducer(state: any, action: { type: MetricsAction; payload?: any }): MetricsContextPropsType {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_RT_QUEUE_METRICS_DATA':
      return {
        ...state,
        rtQueueMetricTimestamp: action.payload.timestamp,
        rtQueueMetrics: action.payload.metrics,
      };
    case 'SET_RT_AGENT_METRICS_DATA':
      return {
        ...state,
        rtAgentMetricTimestamp: action.payload.timestamp,
        rtAgentMetrics: action.payload.metrics,
      };
    case 'SET_RT_RP_METRICS_DATA':
      return {
        ...state,
        rtRoutingProfileMetricTimestamp: action.payload.timestamp,
        rtRoutingProfileMetrics: action.payload.metrics,
      };
    case 'SET_RT_QUEUE_METRICS_INTERVAL':
      return {
        ...state,
        rtQueueMetricsInterval: action.payload.interval,
        rtQueueMetricsIntervalOn: action.payload.on,
      };
    case 'SET_RT_AGENT_METRICS_INTERVAL':
      return {
        ...state,
        rtAgentMetricsInterval: action.payload.interval,
        rtAgentMetricsIntervalOn: action.payload.on,
      };
    case 'SET_RT_RP_METRICS_INTERVAL':
      return {
        ...state,
        rtRoutingProfileMetricsInterval: action.payload.interval,
        rtRoutingProfileMetricsIntervalOn: action.payload.on,
      };
    case 'SET_AVAILABLE_CONNECT_USERS':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_CONTACT_SEARCH_QUERY_RESULTS':
      return {
        ...state,
        runningContactSearchQuery: false,
        contactSearchResults: action.payload.results,
        contactSearchResultsAmt: action.payload.contactSearchResultsAmt,
      };
    case 'START_CONTACT_SEARCH_QUERY':
      return {
        ...state,
        runningContactSearchQuery: true,
      };
    case 'START_CONTACT_DETAIL_QUERY':
      return {
        ...state,
        runningContactDetailQuery: action.payload,
      };
    case 'START_CONTACT_ATTRIBUTES_QUERY':
      return {
        ...state,
        runningContactAttributesQuery: action.payload,
      };
    case 'SET_CONTACT_DETAIL':
      return {
        ...state,
        contactDetail: action.payload.results,
      };
    case 'SET_CONTACT_DETAIL_ATTRIBUTES':
      return {
        ...state,
        contactDetailAttributes: action.payload.results,
      };
    default:
      return state;
  }
}
