import { IValidationErrors } from './apiValidation';

export class ApiError extends Error {
  constructor(
    public status: number,
    public detail: any,
    public errors: any = undefined
  ) {
    super(`${status}: ${typeof detail === 'object' ? JSON.stringify(detail) : detail}`);
    this.name = 'ApiError';
  }
}

export class BadRequestError extends Error {
  public status: number;

  constructor(public errors: IValidationErrors) {
    super('BadRequest');
    this.name = 'BadRequestError';
    this.status = 400;
  }
}

export class NotFoundError extends Error {
  public status: number;

  constructor(message: string) {
    super(message);
    this.name = 'NotFoundError';
    this.status = 404;
  }
}

export class UnauthorizedError extends Error {
  public status: number;

  constructor(message: string) {
    super(message);
    this.name = 'UnauthorizedError';
    this.status = 403;
  }
}
