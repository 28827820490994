/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Menu,
  MenuActionType,
  MenuOption,
  TransferToAgentAction,
  TransferToExternalNumberAction,
  TransferToFlowAction,
  TransferToMenuAction,
  TransferToQueueAction,
} from '@vf-omp/shared';
import { CreateMenuStepsType, UpsertHelpers } from './MenuManagementContext';
import { MenuStateType } from './MenuManagementContextPropTypes';

export interface ValidationError {
  errorMessage: React.ReactNode;
  step: CreateMenuStepsType;
}

export interface ValidatorsType {
  dtmfOptionsWithInvalidActions: string[];
  missingOrInvalidDtmfIndexes: number[];
  dtmfEqualsTermniatingKeyPressIndexes: (number | 'repeat menu option')[];
  validMenuOptionsExist: boolean;
  validIntroPrompt: boolean;
  validMenuKey: boolean;
  validMenuActions: boolean;
  validMenuOptionDtmfs: boolean;
  validTerminatingKeypress: boolean;
  validMenu: boolean;
  isUniqueMenuName: boolean;
  canMoveNext: boolean;
  canJumpSteps: boolean;
  errors: ValidationError[];
}

const useMenuValidators = ({
  menuManagementState,
  upsertHelpers,
}: {
  menuManagementState: MenuStateType;
  upsertHelpers: UpsertHelpers;
}) => {
  const { upsertAction, currentCreateMenuStep } = upsertHelpers;
  const { workingMenu, workingConnectLexBot, menuList } = menuManagementState;
  const { menuOptions = [], terminatingKeypress, defaultMenuOption, repeatMenuOption, menuType } = workingMenu;
  const [validators, setValidators] = useState<ValidatorsType>({
    dtmfOptionsWithInvalidActions: [],
    missingOrInvalidDtmfIndexes: [],
    dtmfEqualsTermniatingKeyPressIndexes: [],
    validMenuOptionsExist: false,
    validIntroPrompt: false,
    validMenuKey: false,
    validMenuActions: false,
    validMenuOptionDtmfs: false,
    validTerminatingKeypress: false,
    isUniqueMenuName: false,
    validMenu: false,
    canMoveNext: false,
    canJumpSteps: false,
    errors: [],
  });

  useEffect(() => {
    const validateMenuOptionsExist = () => menuOptions && menuOptions.length > 0;
    const validateIntroPrompt = () => workingMenu.promptIntro && workingMenu.promptIntro.length > 0;
    const isUniqueMenuName =
      upsertAction !== 'CREATE' ||
      (upsertAction === 'CREATE' && menuList.find(menu => menu.key === workingMenu.key) === undefined);

    const validateMenuKey = () => {
      if (!workingMenu) return false;
      const key = Object.prototype.hasOwnProperty.call(workingMenu, 'key') ? workingMenu.key.trim() : '';
      return !!key;
    };

    const dtmfEqualsTermniatingKeyPressIndexes: (number | 'repeat menu option')[] = menuOptions.reduce(
      (prev, curr, idx) => {
        if (curr?.dtmfOption.length && curr.dtmfOption === terminatingKeypress) return [...prev, idx];
        return prev;
      },
      []
    );
    if (
      workingMenu.repeatMenuOption.dtmfOption.length &&
      workingMenu.repeatMenuOption.dtmfOption === workingMenu.terminatingKeypress
    ) {
      dtmfEqualsTermniatingKeyPressIndexes.push('repeat menu option');
    }

    const dtmfOptionsWithInvalidActions: string[] = [];

    const getActionValidity = (option: MenuOption) => {
      let validity = true;
      if (option.action === undefined) {
        dtmfOptionsWithInvalidActions.push(option.dtmfOption);
        validity = false;
        return validity;
      }

      if (option.action.menuActionType === MenuActionType.TRANSFER_TO_EXTERNAL_NUMBER) {
        const action = option.action as TransferToExternalNumberAction;
        if (!action.externalNumber) {
          dtmfOptionsWithInvalidActions.push(option.dtmfOption);
          validity = false;
        }
      }

      if (option.action.menuActionType === MenuActionType.TRANSFER_TO_FLOW) {
        const action = option.action as TransferToFlowAction;
        if (!action.flowId) {
          dtmfOptionsWithInvalidActions.push(option.dtmfOption);
          validity = false;
        }
      }
      if (option.action.menuActionType === MenuActionType.TRANSFER_TO_MENU) {
        const action = option.action as TransferToMenuAction;
        if (!action.menuKey) {
          dtmfOptionsWithInvalidActions.push(option.dtmfOption);
          validity = false;
        }
      }
      if (option.action.menuActionType === MenuActionType.TRANSFER_TO_QUEUE) {
        const action = option.action as TransferToQueueAction;
        if (!action.queueId || (!action.customerQueueFlowId && !action.queueTreatmentKey)) {
          dtmfOptionsWithInvalidActions.push(option.dtmfOption);
          validity = false;
        }
      }
      if (option.action.menuActionType === MenuActionType.TRANSFER_TO_AGENT) {
        const action = option.action as TransferToAgentAction;
        if (!action.queueId || (!action.customerQueueFlowId && !action.queueTreatmentKey)) {
          dtmfOptionsWithInvalidActions.push(option.dtmfOption);
          validity = false;
        }
      }
      return validity;
    };
    const validateMenuActions = () => {
      let validity = true;
      if (!menuOptions) {
        validity = false;
      }

      for (const option of menuOptions) {
        if (getActionValidity(option) === false) {
          validity = false;
        }
      }
      if (getActionValidity(defaultMenuOption) === false) {
        validity = false;
      }
      return validity;
    };

    const missingOrInvalidDtmfIndexes: number[] = [];
    const validateMenuOptionDtmfs = () => {
      let validity = true;
      if (menuOptions === undefined) {
        validity = false;
        return validity;
      }
      const dtmfCount: { [dtmfOption: string]: number[] } = {};
      menuOptions.forEach((option, idx) => {
        const dtmfOption = option.dtmfOption;
        if (dtmfCount[dtmfOption] === undefined) {
          dtmfCount[dtmfOption] = [];
        }
        dtmfCount[dtmfOption].push(idx);
        if (dtmfOption === undefined || dtmfOption.length === 0) {
          validity = false;
          missingOrInvalidDtmfIndexes.push(idx);
        }
        if (workingMenu.menuType === 'SINGLE_DTMF' && dtmfOption.length > 1) {
          validity = false;
          missingOrInvalidDtmfIndexes.push(idx);
        }
      });

      Object.values(dtmfCount).forEach(arr => {
        if (arr.length > 1) {
          missingOrInvalidDtmfIndexes.push(...arr);
          validity = false;
        }
      });

      const optionStrings = menuOptions.map(m => m.dtmfOption);
      if (optionStrings.includes(workingMenu.repeatMenuOption?.dtmfOption)) {
        const idx = optionStrings.indexOf(workingMenu.repeatMenuOption?.dtmfOption);
        missingOrInvalidDtmfIndexes.push(idx);
        validity = false;
      }

      return validity;
    };

    const validateKeypress = () => {
      const keypressRegex = new RegExp('^[0-9,#,*]{1,5}$');
      return terminatingKeypress ? keypressRegex.test(terminatingKeypress) : false;
    };

    const validMenuOptionDtmfs = validateMenuOptionDtmfs();
    const validTerminatingKeypress = validateKeypress() || workingMenu.menuType !== 'MULTI_DTMF';
    const validIntroPrompt = validateIntroPrompt();
    const validMenuKey = validateMenuKey();
    const validMenuActions = validateMenuActions();
    const validMenuOptionsExist = validateMenuOptionsExist();
    const hasDuplicatedTerminatingKeyPress =
      workingMenu.menuType === 'MULTI_DTMF' &&
      (dtmfEqualsTermniatingKeyPressIndexes.length > 0 ||
        workingMenu.repeatMenuOption.dtmfOption === terminatingKeypress);

    const validMenu =
      validMenuOptionsExist &&
      validIntroPrompt &&
      validMenuKey &&
      validMenuOptionDtmfs &&
      validTerminatingKeypress &&
      validMenuActions &&
      isUniqueMenuName &&
      hasDuplicatedTerminatingKeyPress === false;

    const getErrorMessages = () => {
      if (!workingMenu || isEmpty(workingMenu)) return [];
      const errors: ValidationError[] = [];
      if (!validMenuOptionsExist) {
        errors.push({
          errorMessage: `Menu options ${missingOrInvalidDtmfIndexes.join(',')} are required and must be valid.`,
          step: 'CONFIGURE_OPTIONS',
        });
      }
      if (!validIntroPrompt) {
        errors.push({ errorMessage: `The working menu prompt cannot be empty.`, step: 'CONFIGURE_PROMPTS' });
      }
      if (!validMenuKey) {
        errors.push({ errorMessage: `Menu key (name) does not have a valid value.`, step: 'BASIC_CONFIG' });
      }
      if (!validMenuOptionDtmfs) {
        errors.push({ errorMessage: `The DTMF keys provided are invalid.`, step: 'CONFIGURE_OPTIONS' });
      }
      if (!validTerminatingKeypress) {
        errors.push({ errorMessage: `The terminating keypress is invalid.`, step: 'BASIC_CONFIG' });
      }
      if (!isUniqueMenuName) {
        errors.push({ errorMessage: 'The menu name should be unique.', step: 'BASIC_CONFIG' });
      }
      if (!validMenuActions) {
        errors.push({
          errorMessage: (
            <span>
              Menu options have invalid configurations:
              <br /> {dtmfOptionsWithInvalidActions.join(' | ')}
            </span>
          ),
          step: 'CONFIGURE_OPTIONS',
        });
      }

      if (hasDuplicatedTerminatingKeyPress) {
        errors.push({
          errorMessage: (
            <span>
              The terminating key press is duplicated in dtmf options.
              <br />
              Index:
              <br /> {dtmfEqualsTermniatingKeyPressIndexes.join(' | ')}
            </span>
          ),
          step: 'CONFIGURE_OPTIONS',
        });
      }

      return errors;
    };

    const getCanMoveNext = () => {
      switch (currentCreateMenuStep) {
        case 'ASSOCIATE_BOT':
          return true;
        case 'SELECT_BOT':
          return workingConnectLexBot?.intents?.intentSummaries.length > 0 || upsertAction === 'UPDATE';
        default:
          return true;
      }
    };

    const canMoveNext = getCanMoveNext();

    const getCanJumpNext = () => {
      if (menuType !== 'LEX') return true;
      let allow = true;
      if (workingConnectLexBot?.intents?.intentSummaries.length > 0 === false) {
        allow = false;
      }
      if (menuOptions.length === 0) {
        allow = false;
      }
      return allow;
    };

    const canJumpSteps = getCanJumpNext();

    setValidators(prev => ({
      ...prev,
      dtmfOptionsWithInvalidActions,
      missingOrInvalidDtmfIndexes,
      dtmfEqualsTermniatingKeyPressIndexes,
      validMenu,
      validMenuOptionDtmfs,
      validTerminatingKeypress,
      validIntroPrompt,
      validMenuKey,
      validMenuActions,
      validMenuOptionsExist,
      isUniqueMenuName,
      canMoveNext,
      canJumpSteps,
      errors: getErrorMessages(),
    }));
  }, [
    workingMenu,
    workingConnectLexBot,
    currentCreateMenuStep,
    menuOptions,
    defaultMenuOption,
    repeatMenuOption,
    terminatingKeypress,
  ]);

  const getMenuTypeForLegacyMenu = (menu: Menu): Menu['menuType'] => {
    const lexRegex = new RegExp(/^[A-Za-z]+$/g);
    if (menu.menuType !== undefined) return menu.menuType;
    const isMultiDTMF = menu.menuOptions.find(opt => opt.dtmfOption.length > 1) !== undefined;
    const isLex = menu.menuOptions.find(opt => lexRegex.test(opt.dtmfOption)) !== undefined;
    if (isLex) {
      return 'LEX';
    }
    if (isMultiDTMF) {
      return 'MULTI_DTMF';
    }
    return 'SINGLE_DTMF';
  };

  const validateDtmfString = (dtmf: string, isMulti: boolean) => {
    if (isMulti) {
      const regex = /^[0-9#*]+$/;
      return regex.test(dtmf);
    }
    const regex = /^[0-9#*]$/;
    return regex.test(dtmf);
  };

  const getDtmfExistsInOptions = (dtmfOption: string) => {
    if (!workingMenu) return true;
    return !menuOptions.map(m => m.dtmfOption).includes(dtmfOption);
  };

  return { ...validators, getDtmfExistsInOptions, getMenuTypeForLegacyMenu, validateDtmfString };
};

export default useMenuValidators;
