import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { iNavItemProps } from 'modules/AppRoutes';

const Error401 = lazy(() => import('./Error401'));
const Error404 = lazy(() => import('./Error404'));
const Error500 = lazy(() => import('./Error500'));
const ErrorMaintenance = lazy(() => import('./Maintenance'));

export const ErrorRoutes: iNavItemProps[] = [
  {
    id: '401-error-page',
    messageId: '401 Error Page',
    title: '401 Error Page',
    url: '/error-pages/error-401',
    route: <Route key={`error-page-route-401`} path="/error-pages/error-401" element={<Error401 />} />,
  },
  {
    id: '404-error-page',
    messageId: '404 Error Page',
    title: '404 Error Page',
    url: '/error-pages/error-404',
    route: <Route key={`error-page-route-404`} path="/error-pages/error-404" element={<Error404 />} />,
  },
  {
    id: '500-error-page',
    messageId: '500 Error Page',
    title: '500 Error Page',
    url: '/error-pages/error-500',
    route: <Route key={`error-page-route-500`} path="/error-pages/error-500" element={<Error500 />} />,
  },
  {
    id: 'maintenance-error-page',
    messageId: 'Maintenance Error Page',
    title: 'Maintenance Error Page',
    url: '/error-pages/maintenance',
    route: (
      <Route key={`error-page-route-maintenance`} path="/error-pages/maintenance" element={<ErrorMaintenance />} />
    ),
  },
  {
    id: 'default-error-page',
    messageId: 'Default Error Page',
    title: 'Default Error Page',
    url: '/*',
    route: <Route key={`error-page-route-default`} path="/*" element={<Error404 />} />,
  },
];
