import { PROMPT_TYPES, StrictPromptItem, OpenCheckTypes, ContactCenterStateTypes } from './apiModels';

/**
 * Generic Action behaviors used with Menus and Entry flows
 * @description
 */
export enum FlowActionNameType {
  TRANSFER_TO_QUEUE = 'TRANSFER_TO_QUEUE',
  TRANSFER_TO_EXTERNAL_NUMBER = 'TRANSFER_TO_EXTERNAL_NUMBER',
  TRANSFER_TO_FLOW = 'TRANSFER_TO_FLOW',
  TRANSFER_TO_MENU = 'TRANSFER_TO_MENU',
  TRANSFER_TO_AGENT = 'TRANSFER_TO_AGENT',
  PROMPT_THEN_DISCONNECT = 'PROMPT_THEN_DISCONNECT',
}

export enum YES_NO {
  YES = 'YES',
  NO = 'NO',
}
export enum INPUT_STATUS {
  VALID = 'VALID',
  INVALID = 'INVALID',
}
export enum CHECK_BY_TYPE {
  UNDEFINED = 'UNDEFINED',
  FALSY = 'FALSY',
  EMPTY = 'EMPTY',
}

/**
 * Response objects should be used within the contact flows in connect.
 */
export class BaseActionResponse<T> {
  inputStatus?: INPUT_STATUS;
  actionType?: T;
  menuActionType?: T;
  journeyOverride?: string;
  hasJourneyOverride?: YES_NO;
  updateLanguage?: string;
  invokeModuleArn?: string;
  moduleParams?: { [key: string]: string };
}

export class TransferToQueueActionResponse<T> extends BaseActionResponse<T> {
  actionType?: T;
  queueId?: string;
  setWorkingQueue?: YES_NO;
  isPriority?: YES_NO;
  priority?: string;
  preTransferPrompt?: string;
  queueTransferClosedPrompt?: string;
  playHolidayPrompt: string;
  playClosurePrompt: string;
  preTransferPromptType?: PROMPT_TYPES;
  hasPreTransferPrompt?: YES_NO;
  customerQueueFlowId?: string;
  queueTreatmentKey?: string;
  useQueueTreatmentKey?: YES_NO;
  setCustomerQueueFlow?: YES_NO;
  secondaryOpenCheck: YES_NO;
}

export class TransferToAgentActionResponse<T> extends BaseActionResponse<T> {
  actionType?: T;
  queueId?: string;
  setWorkingQueue?: YES_NO;
  preTransferPrompt?: string;
  queueTransferClosedPrompt?: string;
  playHolidayPrompt: string;
  playClosurePrompt: string;
  preTransferPromptType?: PROMPT_TYPES;
  hasPreTransferPrompt?: YES_NO;
  customerQueueFlowId?: string;
  queueTreatmentKey?: string;
  useQueueTreatmentKey?: YES_NO;
  setCustomerQueueFlow?: YES_NO;
  secondaryOpenCheck: YES_NO;
}

export interface TransferToMenuActionResponse<T> extends BaseActionResponse<T> {
  menuKey?: string;
  setLang?: YES_NO;
  lang?: string;
  preTransferPrompt?: string;
  preTransferPromptType?: PROMPT_TYPES;
  hasPreTransferPrompt?: YES_NO;
}

export interface TransferToExternalNumberActionResponse<T> extends BaseActionResponse<T> {
  externalNumber?: string;
  extension: string;
  hasExtension: YES_NO;
  preTransferPrompt?: string;
  preTransferPromptType?: PROMPT_TYPES;
  hasPreTransferPrompt?: YES_NO;
}

export interface TransferToFlowActionResponse<T> extends BaseActionResponse<T> {
  flowId?: string;
  preTransferPrompt?: string;
  hasPreTransferPrompt?: YES_NO;
}

export interface PromptThenDisconnectResponse<T> extends BaseActionResponse<T> {
  prompt?: string;
  promptType?: PROMPT_TYPES;
  hasPrompt?: YES_NO;
}
//------------------------
// Menu Manager Models
//------------------------

export type MenuPromptItem = StrictPromptItem;

export interface GetMenuDataResponse {
  journeyOverride?: string;
  hasJourneyOverride?: YES_NO;
  retryCount: number;
  greeting?: string;
  greetingType?: PROMPT_TYPES;
  hasGreeting?: YES_NO;
  promptIntro: string;
  promptIntroType: PROMPT_TYPES;
  invalidInputMessage?: string;
  validOptions: string;
  isSimpleMenu: YES_NO;
  menuType: MenuInputType;
  lexAliasArn?: string;
  lexLanguage?: string;
  terminatingKeypress: string;
}
export type MenuPrompt = MenuPromptItem[];

export type MenuList = Menu[];
export type MenuInputType = 'MULTI_DTMF' | 'SINGLE_DTMF' | 'LEX';
export class Menu {
  id?: string;
  key: string;
  menuType: MenuInputType;
  lexAliasArn?: string;
  lexLanguage?: string;
  description?: string;
  journeyOverride?: string;
  defaultMenuOption: MenuOption;
  terminatingKeypress: string = '#';
  repeatMenuOption: MenuOption = {
    dtmfOption: '*',
    action: new RepeatMenuAction(),
  };
  menuOptions: MenuOption[];
  retryCount?: number;
  isEnabled: boolean;

  /**
   * @description un-interruptable message played before going into the menu
   */
  greeting?: MenuPrompt;
  /**
   * @description Interruptable message to be played before listing the menu options
   */
  promptIntro: MenuPrompt;
  /**
   * @description Prompt played in cases of invalid customer menu option input.
   */
  invalidInputMessage?: MenuPrompt;
  /**
   * @description Infers the valid menu options from the actual list of options
   */
  getValidOptions?(): string {
    if (!this.menuOptions || !this.menuOptions.length) throw new Error('Invalid menu option configuration for');
    const arr = this.menuOptions.map(opt => opt.dtmfOption);
    return arr.join(',');
  }
}

export enum MenuActionType {
  TRANSFER_TO_QUEUE = 'TRANSFER_TO_QUEUE',
  TRANSFER_TO_EXTERNAL_NUMBER = 'TRANSFER_TO_EXTERNAL_NUMBER',
  TRANSFER_TO_FLOW = 'TRANSFER_TO_FLOW',
  TRANSFER_TO_MENU = 'TRANSFER_TO_MENU',
  TRANSFER_TO_AGENT = 'TRANSFER_TO_AGENT',
  RETRY_LOOP = 'RETRY_LOOP',
  REPEAT_MENU = 'REPEAT_MENU',
}

export class MenuOptionModule {
  moduleArn: string;
  parameters: { key: string; value: string }[];
}

export class MenuOption {
  dtmfOption: string;
  action: MenuOptionActionType;
}

export class DefaultMenuOption extends MenuOption {
  dtmfOption: 'Timeout';
  action: DefaultMenuOptionActionType;
}

export interface RetryLoopActionResponse<T> extends BaseActionResponse<T> {
  menuActionType?: T;
  retryPrompt?: string;
  hasRetryPrompt?: YES_NO;
  retryPromptType?: PROMPT_TYPES;
}
export interface RepeatMenuActionResponse<T> extends BaseActionResponse<T> {
  menuActionType?: T;
}

export type MenuActionResponse =
  | TransferToQueueActionResponse<MenuActionType.TRANSFER_TO_QUEUE>
  | TransferToAgentActionResponse<MenuActionType.TRANSFER_TO_AGENT>
  | TransferToExternalNumberActionResponse<MenuActionType.TRANSFER_TO_EXTERNAL_NUMBER>
  | TransferToFlowActionResponse<MenuActionType.TRANSFER_TO_FLOW>
  | TransferToMenuActionResponse<MenuActionType.TRANSFER_TO_MENU>
  | RetryLoopActionResponse<MenuActionType.RETRY_LOOP>
  | RepeatMenuActionResponse<MenuActionType.REPEAT_MENU>;

export type MenuOptionActionType =
  | TransferToQueueAction
  | TransferToAgentAction
  | TransferToExternalNumberAction
  | TransferToFlowAction
  | TransferToMenuAction
  | RetryLoopMenuAction
  | RepeatMenuAction;

export type DefaultMenuOptionActionType =
  | TransferToQueueAction
  | TransferToAgentAction
  | TransferToExternalNumberAction
  | TransferToFlowAction
  | TransferToMenuAction
  | RetryLoopMenuAction;

export class MenuOptionAction {
  menuActionType: MenuActionType;
  journeyOverride?: string;
  updateLanguage?: string;
  invokeModule?: MenuOptionModule;
  constructor(key: MenuActionType) {
    this.menuActionType = key;
  }
}
/**
 * @description Transfers the caller to a specific queue
 */
export class TransferToQueueAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.TRANSFER_TO_QUEUE);
  }
  /**
   * If this is NOT set, we will want to use the working Queue
   */
  queueId?: string;
  /**
   * @description Whether the caller should be placed at the top of the queue or not
   */
  isPriority?: boolean = false;
  priority?: string;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  queueTransferClosedPrompt?: MenuPrompt;
  playHolidayPrompt: boolean;
  playClosurePrompt: boolean;
  queueTreatmentKey?: string;
  useQueueTreatmentKey: boolean = false;
  customerQueueFlowId?: string;
  secondaryOpenCheck: boolean;
}

/**
 * @description Transfers the caller to a specific agent queue
 */
export class TransferToAgentAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.TRANSFER_TO_AGENT);
  }
  /**
   * If this is NOT set, we will want to use the working Queue
   */
  queueId?: string;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  queueTransferClosedPrompt?: MenuPrompt;
  playHolidayPrompt: boolean;
  playClosurePrompt: boolean;
  queueTreatmentKey?: string;
  useQueueTreatmentKey: boolean = false;
  customerQueueFlowId?: string;
  secondaryOpenCheck: boolean;
}

/**
 * @description Transfers the caller to a specific queue for an agent interaction (or callback/VM logic)
 */
export class TransferToExternalNumberAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.TRANSFER_TO_EXTERNAL_NUMBER);
  }
  externalNumber: string;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  extension?: string;
}

/**
 * @description Transfers the caller to a specific Contact Flow to continue the engagement
 */
export class TransferToFlowAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.TRANSFER_TO_FLOW);
  }
  flowId: string;
}

/**
 * @description Transfers the caller to a sub-menu
 */
export class TransferToMenuAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.TRANSFER_TO_MENU);
  }
  menuKey: string;
  lang?: string = '';
  preTransferPrompt?: MenuPrompt;
  previousMenuKey?: string;
}

/**
 * @description Transfers the caller to a sub-menu
 */
export class RetryLoopMenuAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.RETRY_LOOP);
  }
  retryPrompt?: MenuPrompt;
}

/**
 * @description Transfers the caller to a sub-menu
 */
export class RepeatMenuAction extends MenuOptionAction {
  constructor() {
    super(MenuActionType.REPEAT_MENU);
  }
}

//------------------------
// Entry Manager Models
//------------------------

/**
 * Get Entry Flow Lambda
 * @param type would be entry in this use case
 * @param DNIS phone number being called (could be inferred)
 *
 * @description
 * 1) Call OMP app fn to get Queue (return all data)
 * 2) Using queue name lookup hours
 * 3) Check state of contact center
 * 4) Return behavior obj based on the provided state
 *
 * @returns GetEntryDataResponse
 */

export type FlowDataList = FlowData[];
export type FlowDataPromptItem = StrictPromptItem;
export type FlowDataPrompt = FlowDataPromptItem[];

/**
 * Used by OMP frontend
 */
export type FlowData = EntryFlowData | QueueFlowData;

export type FlowActionsType =
  | TransferToQueueContactFlowContactFlowAction
  | TransferToAgentContactFlowContactFlowAction
  | TransferToExternalNumberContactFlowAction
  | TransferToFlowContactFlowAction
  | PromptThenDisconnectFlowAction
  | TransferToMenuContactFlowAction;

/**
 * Entry Point Selection Types
 */
export enum EntryPointTypes {
  DNIS = 'DNIS',
  CUSTOM = 'CUSTOM',
}

export interface EntryFlowData {
  id?: string; // Would be flow type? (entry)
  key: string; // Would be DNIS or customer attribute value
  entryPointType: EntryPointTypes;
  description?: string;
  defaultJourneyOverride?: string;
  isEnabled: boolean;
  openCheckKey: string;
  openCheckType: OpenCheckTypes;
  defaultQueueId: string;
  defaultLang: string;
  useDefaultQueueTreatmentKey: boolean;
  defaultQueueTreatmentKey?: string;
  defaultCustomerQueueFlowId: string;
  defaultWhisperFlow: string;
  defaultHoldFlow: string;
  defaultDisconnectFlow: string;
  errorMsg?: FlowDataPrompt;
  invalidInputMsg?: FlowDataPrompt;
  afterHoursMsg: FlowDataPrompt;
  [ContactCenterStateTypes.OPEN]: FlowActionsType;
  [ContactCenterStateTypes.CLOSED]: FlowActionsType;
  [ContactCenterStateTypes.CLOSURE]: FlowActionsType;
  [ContactCenterStateTypes.HOLIDAY]: FlowActionsType;
}

export type GetEntryDataResponse = GetFlowDataResponse & {
  contactCenterState: ContactCenterStateTypes;
  defaultJourneyOverride?: string;
  hasDefaultJourneyOverride?: YES_NO;
  defaultQueueId: string;
  setDefaultWorkingQueue: YES_NO;
  defaultCustomerQueueFlowId: string;
  defaultQueueTreatmentKey: string;
  setDefaultCustomerQueueFlow: YES_NO;
  useDefaultQueueTreatmentKey: YES_NO;
  defaultWhisperFlowId: string;
  setDefaultWhisperFlow: YES_NO;
  defaultHoldFlowId: string;
  setDefaultHoldFlow: YES_NO;
  defaultDisconnectFlowId: string;
  setDefaultDisconnectFlow: YES_NO;
  invalidInputMsg?: string;
  hasInvalidInputMsg?: YES_NO;
  invalidInputMsgType?: PROMPT_TYPES;
  errorMsg?: string;
  hasErrorMsg?: YES_NO;
  errorMsgType?: PROMPT_TYPES;
  afterHoursMsg?: string;
  hasAfterHoursMsg?: YES_NO;
  afterHoursMsgType?: PROMPT_TYPES;
  lang: string;
};

/**
 * @description Integrated in connect to determine contact flow behavior
 */

export type GetFlowDataResponse =
  | TransferToQueueActionResponse<FlowActionNameType.TRANSFER_TO_QUEUE>
  | TransferToAgentActionResponse<FlowActionNameType.TRANSFER_TO_AGENT>
  | TransferToExternalNumberActionResponse<FlowActionNameType.TRANSFER_TO_EXTERNAL_NUMBER>
  | TransferToFlowActionResponse<FlowActionNameType.TRANSFER_TO_FLOW>
  | TransferToMenuActionResponse<FlowActionNameType.TRANSFER_TO_MENU>
  | PromptThenDisconnectResponse<FlowActionNameType.PROMPT_THEN_DISCONNECT>;

export class ContactFlowAction {
  actionRef: string; // Used to reference this action b/c we store this obj nested
  contactFlowActionType: FlowActionNameType;
  journeyOverride?: string;
  constructor(key: FlowActionNameType) {
    this.contactFlowActionType = key;
  }
}
/**
 * @description Transfers the caller to a specific queue
 */
export class TransferToQueueContactFlowContactFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof TransferToQueueContactFlowContactFlowAction]?: any }) {
    super(FlowActionNameType.TRANSFER_TO_QUEUE);
    if (input) Object.assign(this, input);
  }
  /**
   * If this is NOT set, we will want to use the working Queue
   */
  queueId?: string;
  /**
   * @description Whether the caller should be placed at the top of the queue or not
   */
  isPriority?: boolean = false;
  priority?: string;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  queueTransferClosedPrompt?: MenuPrompt;
  queueTreatmentKey?: string;
  playHolidayPrompt: boolean;
  playClosurePrompt: boolean;
  secondaryOpenCheck: boolean;
  useQueueTreatmentKey: boolean = false;
  customerQueueFlowId?: string;
}

/**
 * @description Transfers the caller to a specific agent queue
 */
export class TransferToAgentContactFlowContactFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof TransferToAgentContactFlowContactFlowAction]?: any }) {
    super(FlowActionNameType.TRANSFER_TO_AGENT);
    if (input) Object.assign(this, input);
  }
  /**
   * If this is NOT set, we will want to use the working Queue
   */
  queueId?: string;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  queueTransferClosedPrompt?: MenuPrompt;
  queueTreatmentKey?: string;
  playHolidayPrompt: boolean;
  playClosurePrompt: boolean;
  secondaryOpenCheck: boolean;
  useQueueTreatmentKey: boolean = false;
  customerQueueFlowId?: string;
}

/**
 * @description Transfers the caller to a specific queue for an agent interaction (or callback/VM logic)
 */
export class TransferToExternalNumberContactFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof TransferToExternalNumberContactFlowAction]?: any }) {
    super(FlowActionNameType.TRANSFER_TO_EXTERNAL_NUMBER);
    if (input) Object.assign(this, input);
  }
  externalNumber: string;
  /**
   * @description The prompt to be played before transferring a caller
   */
  preTransferPrompt?: MenuPrompt;
  extension?: string;
}

/**
 * @description Transfers the caller to a specific Contact Flow to continue the engagement
 */
export class TransferToFlowContactFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof TransferToFlowContactFlowAction]?: any }) {
    super(FlowActionNameType.TRANSFER_TO_FLOW);
    if (input) Object.assign(this, input);
  }
  flowId: string;
  preTransferPrompt?: MenuPrompt;
}

/**
 * @description Transfers the caller to a sub-menu
 */
export class TransferToMenuContactFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof TransferToMenuContactFlowAction]?: any }) {
    super(FlowActionNameType.TRANSFER_TO_MENU);
    if (input) Object.assign(this, input);
  }
  menuKey: string;
  lang?: string = '';
  preTransferPrompt?: MenuPrompt;
}

/** Prompt Transfers the caller to a sub-menu
 */
export class PromptThenDisconnectFlowAction extends ContactFlowAction {
  constructor(input?: { [String in keyof PromptThenDisconnectFlowAction]?: any }) {
    super(FlowActionNameType.PROMPT_THEN_DISCONNECT);
    if (input) Object.assign(this, input);
  }
  prompt: MenuPrompt;
  promptType: PROMPT_TYPES;
}

//------------------------
// Queue Flow Manager Models
//------------------------

/**
* 
* id (queue-flow)
* key / queueFlowKey
* lang 
* description
* journeyOverride
* isPriority
* priorityLevel (default 1)
* loopInitializationPrompt
* loopInitializationPromptType
* ActionType
* interruptSetting
* holdMusicId
* holdMusicName
* queueFlowId


* interrupt flows
* --------------------* 
* BASIC PROMPT
* -------------------
* interruptMsg
* interruptPMsgType
* 
* CALLBACK OFFER
* ------------------
* callbackOfferMsg
* callbackOfferMsgType
* callingPhoneMsg
* callingPhoneMsgType
* callbackConfirmMsg
* callbackConfirmMsgType
* enterCallbackPhoneMsg
* enterCallbackPhoneMsgType
* confirmNewCallbackPhoneMsg
* confirmNewCallbackPhoneMsgType
* thankyouMsg
* thankyouMsgType
* 
* VOICEMAIL OFFER
* ---------------------
* voicemailOfferMsg
* voicemailOfferMsgType
* mailboxExtension
* voicemailThanksMsg
* voicemailThanksMsgType
* 
* CALLBACK VOICEMAIL OFFER
* ------------------
* callbackOrVoicemailOfferMsg
* callbackOrVoicemailOfferMsgType
* callingPhoneMsg
* callingPhoneMsgType
* callbackConfirmMsg
* callbackConfirmMsgType
* enterCallbackPhoneMsg
* enterCallbackPhoneMsgType
* confirmNewCallbackPhoneMsg
* confirmNewCallbackPhoneMsgType
* thankyouMsg
* thankyouMsgType
* mailboxExtension
* voicemailThanksMsg
* voicemailThanksMsgType
* 
*/
export type CallerPrompt = StrictPromptItem[];

export enum QueueFlowTypes {
  BASIC_HOLD = 'BASIC_HOLD',
  INTERRUPT_ACTION = 'INTERRUPT_ACTION',
}
export enum QueueFlowActionTypes {
  BASIC_HOLD = 'BASIC_HOLD',
  BASIC_PROMPT = 'BASIC_PROMPT',
  CALLBACK_OFFER = 'CALLBACK_OFFER',
  VOICEMAIL_OFFER = 'VOICEMAIL_OFFER',
  CALLBACK_VOICEMAIL_OFFER = 'CALLBACK_VOICEMAIL_OFFER',
  // WAIT_TIME = 'WAIT_TIME',
  // WAIT_TIME_CALLBACK = 'WAIT_TIME_CALLBACK',
}

/**
 * Matches a QFE flow to a prompt and interrupt
 */
export interface QueueFlowMapper {
  default: {
    INTERRUPT_ACTION: {
      '15_SECONDS': Record<string, string>;
      '30_SECONDS': Record<string, string>;
      '60_SECONDS': Record<string, string>;
      '90_SECONDS': Record<string, string>;
      '120_SECONDS': Record<string, string>;
      '180_SECONDS': Record<string, string>;
    };
    BASIC_HOLD: Record<string, string>;
  };
  custom: {
    INTERRUPT_ACTION: unknown;
  };
}
export interface GetQueueFlowDataResponse {
  id?: string;
  key?: string;
  lang: string;
  queueFlowId: string;
  // NEW ATTRS
  slotOnePrompt?: string;
  slotTwoPrompt?: string;
  slotThreePrompt?: string;
  slotFourPrompt?: string;
  slotOneOffer?: QueueTreatmentOffer;
  slotTwoOffer?: QueueTreatmentOffer;
  slotThreeOffer?: QueueTreatmentOffer;
  slotFourOffer?: QueueTreatmentOffer;
  voicemailOfferMessage?: string;
  callbackOfferMessage?: string;
  vmCbOfferMessage?: string;
  invalidDtmfMessage?: string;
  callbackConfirmMessage?: string;
  enterCallbackNumberMessage?: string;
  invalidCallbackNumberMessage?: string;
  voicemailThankyouMessage?: string;
  callbackThankyouMessage?: string;
  maxLoops?: string;
  preDisconnectMessage?: string;
  preDisconnectOffer?: QueueTreatmentOffer;
  mailboxExtSource: MailboxExtensionSource;
  mailboxExtension?: string;
  voicemailCallbackErrorAction: VoicemailCallbackErrorAction;
  voicemailCallbackErrorMessage?: string;
}

export enum QueueFlowServerErrorTypes {
  NO_PROMPT_CONFIGURED_FOR_LANGUAGE = 'NO_PROMPT_CONFIGURED_FOR_LANGUAGE',
  MISSING_REQUIRED_ATTRIBUTES = 'MISSING_REQUIRED_ATTRIBUTES',
}
export class QueueFlowServerErrorDefaultMsgs {
  defaultMsgs = {
    [QueueFlowServerErrorTypes.NO_PROMPT_CONFIGURED_FOR_LANGUAGE]: 'No prompt configured for language',
    [QueueFlowServerErrorTypes.MISSING_REQUIRED_ATTRIBUTES]: 'Missing required attributes',
  };
}

export type GetQueueFlowDataActionResponse =
  | BasicHoldQFEResponse
  | BasicPromptQFEResponse
  | CallbackOfferQFEResponse
  | VoicemailOfferQFEResponse
  | CallbackOrVoicemailOfferQFEResponse;

export class BaseQFEActionResponse {
  actionType: QueueFlowActionTypes;
  qfe_journeyOverride: string;
  has_qfe_journeyOverride: YES_NO;
  hasServerError?: YES_NO;
  serverErrorType?: QueueFlowServerErrorTypes;
  serverErrorMsg?: string;
}
export interface BasicHoldQFEResponse extends BaseQFEActionResponse {}
export interface BasicPromptQFEResponse extends BaseQFEActionResponse {
  interruptMsg: string;
  interruptMsgType: PROMPT_TYPES;
}
export interface CallbackOfferQFEResponse extends BaseQFEActionResponse {
  callbackOfferMsg: string;
  callbackOfferMsgType: PROMPT_TYPES;
  confirmNewCallbackPhoneMsg: string;
  confirmNewCallbackPhoneMsgType: PROMPT_TYPES;
  invalidPhoneMsg: string;
  invalidPhoneMsgType: PROMPT_TYPES;
  callingPhoneMsg: string;
  callingPhoneMsgType: PROMPT_TYPES;
  callbackConfirmMsg: string;
  callbackConfirmMsgType: PROMPT_TYPES;
  thankyouMsg: string;
  thankyouMsgType: PROMPT_TYPES;
  enterCallbackPhoneMsg: string;
  enterCallbackPhoneMsgType: PROMPT_TYPES;
}
export interface VoicemailOfferQFEResponse extends BaseQFEActionResponse {
  voicemailOfferMsg: string;
  voicemailOfferMsgType: PROMPT_TYPES;
  voicemailThanksMsg: string;
  voicemailThanksMsgType: PROMPT_TYPES;
  mailboxExtension: string;
}
export interface CallbackOrVoicemailOfferQFEResponse extends BaseQFEActionResponse {
  callbackOrVoicemailOfferMsg: string;
  callbackOrVoicemailOfferMsgType: PROMPT_TYPES;
  confirmNewCallbackPhoneMsg: string;
  confirmNewCallbackPhoneMsgType: PROMPT_TYPES;
  invalidPhoneMsg: string;
  invalidPhoneMsgType: PROMPT_TYPES;
  callingPhoneMsg: string;
  callingPhoneMsgType: PROMPT_TYPES;
  callbackConfirmMsg: string;
  callbackConfirmMsgType: PROMPT_TYPES;
  thankyouMsg: string;
  thankyouMsgType: PROMPT_TYPES;
  enterCallbackPhoneMsg: string;
  enterCallbackPhoneMsgType: PROMPT_TYPES;
  voicemailThanksMsg: string;
  voicemailThanksMsgType: PROMPT_TYPES;
  mailboxExtension: string;
}
export type MailboxExtensionSource = 'WORKING_QUEUE_NAME' | 'OMP_TABLE' | 'UPSTREAM';
export type VoicemailCallbackErrorAction = 'CONTINUE_HOLDING' | 'PROMPT_DISCONNECT';
export type QueueTreatmentOffer = 'VOICEMAIL' | 'CALLBACK' | 'VOICEMAIL_OR_CALLBACK' | 'NONE';
export class QueueFlowData {
  id?: string;
  key: string;
  lang: string;
  description?: string;
  isEnabled: boolean;
  queueFlowId: string;
  slotOnePrompt?: FlowDataPrompt;
  slotTwoPrompt?: FlowDataPrompt;
  slotThreePrompt?: FlowDataPrompt;
  slotFourPrompt?: FlowDataPrompt;
  slotOneOffer?: QueueTreatmentOffer;
  slotTwoOffer?: QueueTreatmentOffer;
  slotThreeOffer?: QueueTreatmentOffer;
  slotFourOffer?: QueueTreatmentOffer;
  voicemailOfferMessage?: FlowDataPrompt;
  callbackOfferMessage?: FlowDataPrompt;
  enterCallbackNumberMessage?: FlowDataPrompt;
  vmCbOfferMessage?: FlowDataPrompt;
  invalidDtmfMessage?: FlowDataPrompt;
  callbackConfirmMessage?: FlowDataPrompt;
  invalidCallbackNumberMessage?: FlowDataPrompt;
  voicemailThankyouMessage?: FlowDataPrompt;
  callbackThankyouMessage?: FlowDataPrompt;
  maxLoops?: string;
  preDisconnectMessage?: FlowDataPrompt;
  preDisconnectOffer?: QueueTreatmentOffer;
  mailboxExtSource: MailboxExtensionSource;
  mailboxExtension?: string;
  voicemailCallbackErrorAction: VoicemailCallbackErrorAction;
  voicemailCallbackErrorMessage?: FlowDataPrompt;
}

export class QueueFlowAction {
  actionType: QueueFlowActionTypes;
  qfe_journeyOverride: string;
  constructor(key: QueueFlowActionTypes) {
    this.actionType = key;
  }
}
export class BasicHoldQueueFlowAction extends QueueFlowAction {
  constructor(input?: { [String in keyof BasicHoldQueueFlowAction]?: any }) {
    super(QueueFlowActionTypes.BASIC_HOLD);
    if (input) Object.assign(this, input);
  }
}

export class BasicPromptQueueFlowAction extends QueueFlowAction {
  interruptMsg: FlowDataPrompt;
  constructor(input?: { [String in keyof BasicPromptQueueFlowAction]?: any }) {
    super(QueueFlowActionTypes.BASIC_PROMPT);
    if (input) Object.assign(this, input);
  }
}

export class CallbackOfferQueueFlowAction extends QueueFlowAction {
  callbackOfferMsg: FlowDataPrompt;
  confirmNewCallbackPhoneMsg: FlowDataPrompt;
  invalidPhoneMsg: FlowDataPrompt;
  callingPhoneMsg: FlowDataPrompt;
  callbackConfirmMsg: FlowDataPrompt;
  thankyouMsg: FlowDataPrompt;
  enterCallbackPhoneMsg: FlowDataPrompt;
  constructor(input?: {
    [String in keyof CallbackOfferQueueFlowAction]?: any;
  }) {
    super(QueueFlowActionTypes.CALLBACK_OFFER);
    if (input) Object.assign(this, input);
  }
}

export class VoicemailOfferQueueFlowAction extends QueueFlowAction {
  voicemailOfferMsg: FlowDataPrompt;
  voicemailThanksMsg: FlowDataPrompt;
  mailboxExtension: string;
  constructor(input?: {
    [String in keyof VoicemailOfferQueueFlowAction]?: any;
  }) {
    super(QueueFlowActionTypes.VOICEMAIL_OFFER);
    if (input) Object.assign(this, input);
  }
}
export class CallbackOrVoicemailOfferQueueFlowAction extends QueueFlowAction {
  callbackOrVoicemailOfferMsg: FlowDataPrompt;
  confirmNewCallbackPhoneMsg?: FlowDataPrompt;
  invalidPhoneMsg?: FlowDataPrompt;
  callingPhoneMsg?: FlowDataPrompt;
  callbackConfirmMsg?: FlowDataPrompt;
  thankyouMsg?: FlowDataPrompt;
  enterCallbackPhoneMsg?: FlowDataPrompt;
  voicemailThanksMsg?: FlowDataPrompt;
  mailboxExtension?: string;
  constructor(input?: { [String in keyof CallbackOrVoicemailOfferQueueFlowAction]?: any }) {
    super(QueueFlowActionTypes.CALLBACK_VOICEMAIL_OFFER);
    if (input) Object.assign(this, input);
  }
}
// export class WaitTimeQueueFlowAction extends QueueFlowAction {
//   constructor(input?: { [String in keyof WaitTimeQueueFlowAction]?: any }) {
//     super(QueueFlowActionTypes.WAIT_TIME);
//     if (input) Object.assign(this, input);
//   }
// }

// export class WaitTimeCallbackQueueFlowAction extends QueueFlowAction {
//   constructor(input?: { [String in keyof WaitTimeCallbackQueueFlowAction]?: any }) {
//     super(QueueFlowActionTypes.WAIT_TIME_CALLBACK);
//     if (input) Object.assign(this, input);
//   }
// }
