import TenantContextPropsType from './ContextPropTypes';

const config: TenantContextPropsType = {
  loadingData: false,
  availableTenants: [],
  availableTenantsLoaded: false,
  currentTenant: null,
  currentTenantLoaded: false,
  error: '',
  msg: null,
};

export default config;
