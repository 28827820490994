import { MenuStateType } from './MenuManagementContextPropTypes';

export type MenuActions =
  | 'DELETE_MENU'
  | 'UPSERT_MENU'
  | 'GET_MENU'
  | 'LIST_MENUS'
  | 'GET_QUEUES'
  | 'GET_AGENTS'
  | 'GET_FLOWS'
  | 'GET_MODULES'
  | 'GET_PROMPTS'
  | 'SET_CUSTOMER_QUEUE_FLOWS'
  | 'LOADING_MENUS'
  | 'DELETING_MENU'
  | 'LOADING_QUEUE_TREATMENT'
  | 'LIST_QUEUE_TREATMENT'
  | 'SET_WORKING_MENU'
  | 'EDIT_MENU'
  | 'SET_CONNECT_LEX_BOTS'
  | 'SET_WORKING_CONNECT_LEX_BOT';

export function menuReducer(state: MenuStateType, action: { type: MenuActions; payload: any }): MenuStateType {
  switch (action.type) {
    case 'DELETE_MENU': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deletingMenu: false,
      };
    }
    case 'DELETING_MENU': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deletingMenu: action.payload,
      };
    }
    case 'UPSERT_MENU': {
      // @todo Potentially append menu in list instead of listing again
      return state;
    }
    case 'GET_MENU': {
      // @todo append latest menu to list so the newest content is there in state
      return {
        ...state,
        workingMenu: action.payload,
      };
    }
    case 'LOADING_MENUS': {
      return {
        ...state,
        loadingMenus: action.payload,
      };
    }
    case 'LIST_MENUS': {
      return {
        ...state,
        menusLoaded: true,
        loadingMenus: false,
        menuList: action.payload,
      };
    }
    case 'GET_QUEUES':
      return {
        ...state,
        queuesLoaded: true,
        queues: action.payload,
      };
    case 'GET_AGENTS':
      return {
        ...state,
        agentsLoaded: true,
        agentQueues: action.payload,
      };
    case 'GET_FLOWS':
      return {
        ...state,
        flowsLoaded: true,
        flows: action.payload,
      };
    case 'GET_MODULES':
      return {
        ...state,
        modulesLoaded: true,
        modules: action.payload,
      };
    case 'GET_PROMPTS':
      return {
        ...state,
        promptsLoaded: true,
        prompts: action.payload,
      };
    case 'SET_CUSTOMER_QUEUE_FLOWS':
      return {
        ...state,
        customerQueueFlows: action.payload,
      };
    case 'LOADING_QUEUE_TREATMENT': {
      return {
        ...state,
        loadingQueueTreatment: action.payload,
      };
    }
    case 'LIST_QUEUE_TREATMENT': {
      return {
        ...state,
        queueTreatmentsLoaded: true,
        loadingQueueTreatment: false,
        queueTreatmentList: action.payload,
      };
    }
    case 'SET_WORKING_MENU': {
      return {
        ...state,
        workingMenu: action.payload,
      };
    }
    case 'EDIT_MENU': {
      return {
        ...state,
        [action.payload.name]: action.payload,
      };
    }

    case 'SET_CONNECT_LEX_BOTS': {
      return {
        ...state,
        connectLexBots: action.payload.connectLexBots,
        allLexBotSummaries:
          action.payload?.allLexBotSummaries.length > 0 ? action.payload.allLexBotSummaries : state.allLexBotSummaries,
      };
    }

    case 'SET_WORKING_CONNECT_LEX_BOT': {
      return {
        ...state,
        workingConnectLexBot: action.payload,
      };
    }

    default:
      return state;
  }
}
