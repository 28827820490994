import { getLogger } from '@vf/services/LoggingService';
import { ContactFlow, ContactFlowModule, PhoneNumberSummary, PromptSummary } from '@aws-sdk/client-connect';
import { HelperApi, ConnectQueue } from '@vf-omp/shared';
import { ApiClientBase } from './ApiClientBase';

export class HelpersApiClient extends ApiClientBase implements HelperApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('HelpersApiClient'), useAuth);
  }

  async getAllQueues(): Promise<ConnectQueue[]> {
    const result = await this.get('/api/helpers/queues');
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }
  async getAgentQueues(): Promise<ConnectQueue[]> {
    const result = await this.get('/api/helpers/agents');
    return result.json();
  }
  async getAllFlows(): Promise<ContactFlow[]> {
    const result = await this.get('/api/helpers/flows');
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }
  async getAllModules(): Promise<ContactFlowModule[]> {
    const result = await this.get('/api/helpers/modules');
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }
  async getAllPrompts(): Promise<PromptSummary[]> {
    const result = await this.get('/api/helpers/prompts');
    return (await result.json()).sort((a, b) => a.Name.localeCompare(b.Name));
  }
  async getAllNumbers(): Promise<PhoneNumberSummary[]> {
    const result = await this.get('/api/helpers/numbers');
    return await result.json();
  }
  async getAllTimezones(): Promise<string[]> {
    const result = await this.get('/api/helpers/timezones');
    return await result.json();
  }
}
