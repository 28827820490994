import MetricsContextPropsType from './ContextPropTypes';

const config: MetricsContextPropsType = {
  loadingMetrics: false,
  rtQueueMetricTimestamp: '',
  rtQueueMetrics: [],
  rtQueueMetricsInterval: null,
  rtQueueMetricsIntervalOn: false,
  rtAgentMetricTimestamp: '',
  rtAgentMetrics: [],
  rtAgentMetricsInterval: null,
  rtAgentMetricsIntervalOn: false,
  rtRoutingProfileMetricTimestamp: '',
  rtRoutingProfileMetrics: [],
  rtRoutingProfileMetricsInterval: null,
  rtRoutingProfileMetricsIntervalOn: false,

  availableConnectUsers: [],
  availablePhoneNumbers: [],
  availableQueues: [],

  contactSearchResults: [],
  contactSearchResultsAmt: 0,
  contactDetail: {},
  contactDetailAttributes: [],
  runningContactSearchQuery: false,
  runningContactDetailQuery: false,
  runningContactAttributesQuery: false,
  error: '',
};

export default config;
