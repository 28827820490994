import UserManagementContextPropsType from './ManagementContextPropTypes';

type UserManagementAction =
  | 'FETCH_COGNITO_USERS'
  | 'COGNITO_USERS_LOADED'
  | 'SET_COGNITO_USERS'
  | 'ROLES_LOADED'
  | 'ROLE_DETAIL_LOADED'
  | 'SET_ROLES'
  | 'SET_ROLE_DETAIL'
  | 'POLICIES_LOADED'
  | 'SET_POLICIES'
  | 'SET_POLICY_DETAIL'
  | 'SET_POLICY_CONFIG_KEYS'
  | 'SET_DEFAULT_POLICY'
  | 'FETCHING_DATA'
  | 'FETCHING_SUCCESS'
  | 'FETCHING_ERROR'
  | 'SET_MESSAGE'
  | 'CLEAR_STATE';

export function reducer(
  state: UserManagementContextPropsType,
  action: { type: UserManagementAction; payload?: any }
): UserManagementContextPropsType {
  switch (action.type) {
    case 'FETCHING_DATA':
      return {
        ...state,
        loadingData: true,
      };
    case 'CLEAR_STATE':
      return {
        ...action.payload,
      };
    case 'FETCHING_SUCCESS':
      return {
        ...state,
        loadingData: false,
      };
    case 'FETCHING_ERROR':
      return {
        ...state,
        loadingData: false,
        msg: action.payload.msg,
      };
    case 'SET_COGNITO_USERS': {
      return {
        ...state,
        appUsersLoaded: true,
        appUsers: action.payload,
      };
    }
    case 'SET_ROLES': {
      return {
        ...state,
        loadingData: false,
        rolesLoaded: true,
        roles: action.payload,
      };
    }
    case 'SET_ROLE_DETAIL': {
      return {
        ...state,
        loadingData: false,
        roleDetailLoaded: true,
        roleDetail: action.payload,
      };
    }
    case 'SET_POLICY_DETAIL': {
      return {
        ...state,
        policyDetailLoaded: true,
        policyDetail: action.payload,
      };
    }
    case 'SET_POLICIES': {
      return {
        ...state,
        loadingData: false,
        policiesLoaded: true,
        policies: action.payload,
      };
    }
    case 'SET_DEFAULT_POLICY': {
      return {
        ...state,
        defaultPolicy: action.payload,
      };
    }
    case 'SET_MESSAGE':
      return {
        ...state,
        msg: action.payload.msg,
      };
    case 'SET_POLICY_CONFIG_KEYS':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
